import { RegisterFormDataType } from "@/models/user.interface";
import client from "./client";

interface ILogin {
  email: string;
  password: string;
}

export const loginUser = async (loginInfo: ILogin) => {
  const form_data = new FormData();

  form_data.append("username", loginInfo.email);
  form_data.append("password", loginInfo.password);

  const user = await client.post("/auth/login", form_data);
  return user;
};

export const forgetPassword = async (email: string) => {
  const form_data = new FormData();

  form_data.append("email", email);

  const user = await client.post("/verification/send/password_recovery", form_data);
  return user;
};

export const loginWithGoogle = async (code: string) => {
  const user = await client.get(
    `/auth/google?code=${code}&redirectUrl=http://localhost:8080/login`
  );
  return user;
};

export const registerUser = async (registerData: RegisterFormDataType) => {
  const tempData = { ...registerData };
  /*   tempData.phone_number = tempData.countrycode + tempData.phone_number;
  delete tempData.countrycode; */

  const user = await client.post("/partner/auth/signup", tempData);
  return user;
};

export const authMe = async () => {
  const data = await client.get("partner/auth/me");
  return data;
};

export const updateAuthMe = async (data: {
  avatar: File | null;
  title: string;
  description: string;
  partner_roles: string[];
  status: string;
}) => {
  const form_data = new FormData();

  data.avatar !== null && form_data.append("avatar", data.avatar);
  data.title !== "" && form_data.append("title", data.title);
  data.description !== "" && form_data.append("description", data.description);
  for (const partner_role of data.partner_roles) form_data.append("partner_roles", partner_role);
  data.status !== "" && form_data.append("status", data.status);

  const user = await client.put("partner/auth/me", form_data);
  return user;
};
