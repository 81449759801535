import { fetchRoadmapApplicationHistory } from "@/api/partners";
import { fetchRoadmapCategories, putRoadmap } from "@/api/roadmap";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import WhiteButton from "@/components/buttons/WhiteButton";
import { createStrategy, selectedTasks } from "@/store/atoms/createStrategy.atoms";
import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

const steps = [
  { name: "Step 1", href: "#", status: "complete" },
  { name: "Step 2", href: "#", status: "complete" },
  { name: "Step 3", href: "#", status: "complete" },
  { name: "Step 4", href: "#", status: "current" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
type Props = {
  setStep: (step: number) => void;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  selectedId: number;
};

const Review = (props: Props) => {
  const { setStep, setOpen, refetch, selectedId } = props;
  const [newStrategy] = useRecoilState(createStrategy);
  const [selectedTaskList] = useRecoilState(selectedTasks);

  const { data, isLoading: isLoadingCategories } = useQuery(
    "fetchRoadmapCategories",
    fetchRoadmapCategories
  );

  const { mutateAsync, isLoading } = useMutation(putRoadmap);

  const handleCreateStrategy = () => {
    mutateAsync(newStrategy)
      .catch((err) => toast.error(err?.response?.data?.message) || console.log(err))
      .finally(() => {
        refetch();
        setOpen(false);
      });
  };

  const { data: applicationHistory, isLoading: isLoadingHistory } = useQuery(
    "fetchRoadmapApplicationHistory",
    () => fetchRoadmapApplicationHistory(selectedId)
  );

  return (
    <div>
      <div className="pb-7">
        <div className="font-medium text-lg">Strategy view</div>
        <div className="text-gray-500 text-sm">
          Here’s your strategy detail. If you think all is done you can click on create button and
          submit to the approval.
        </div>
      </div>
      <div>
        {isLoadingCategories || isLoadingHistory ? (
          <LoadingSpinner size="w-5 h-5" />
        ) : (
          <>
            <div className="flex items-center border-y py-8">
              <div className="border p-5 text-sm space-y-5 w-full">
                {applicationHistory?.map((history: any) => (
                  <div
                    className={`text-black p-2 rounded flex items-center gap-2 ${
                      history.status === "rejected" ? "bg-green-100" : "bg-red-100"
                    }`}
                    key={history.id}>
                    <div>{history.message}</div>
                  </div>
                ))}
                <div className="grid grid-cols-2 gap-5 ">
                  <div>
                    <div className="text-gray-500">Strategy Name</div>
                    <div>{newStrategy?.roadmap?.name}</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Strategy Label</div>
                    <div>{newStrategy?.roadmap?.category_id}</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Operating time</div>
                    <div>
                      {selectedTaskList.reduce(
                        (acc, task) => acc + task.total_estimated_time ?? 0,
                        0
                      )}{" "}
                      day
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-500">Total credit</div>
                    <div>
                      {selectedTaskList.reduce((acc, task) => acc + task.total_credit ?? 0, 0)}{" "}
                      credits
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="text-gray-500">Description</div>
                    <div>{newStrategy?.roadmap?.description}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="text-gray-500">Tasks</div>
                  </div>
                  <div className="border  rounded divide-y mt-2">
                    {selectedTaskList.map((task, index) => (
                      <div key={task.id} className="flex justify-between items-center p-2">
                        <div className="flex items-center gap-2">
                          <div className="">{index + 1} -</div>
                          <div>{task.story_name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center py-5">
              <nav>
                <ol className="flex items-center">
                  {steps.map((step, stepIdx) => (
                    <li
                      key={step.name}
                      className={classNames(
                        stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                        "relative"
                      )}>
                      {step.status === "complete" ? (
                        <>
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-indigo-600" />
                          </div>
                          <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 ">
                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            <span className="sr-only">{step.name}</span>
                          </div>
                        </>
                      ) : step.status === "current" ? (
                        <>
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-gray-200" />
                          </div>
                          <div
                            className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                            aria-current="step">
                            <span
                              className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                              aria-hidden="true"
                            />
                            <span className="sr-only">{step.name}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-gray-200" />
                          </div>
                          <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                            <span className="sr-only">{step.name}</span>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>

            <div className="flex gap-4 justify-end mt-4">
              <WhiteButton
                onClick={() => {
                  setStep(3);
                }}
                className="w-fit">
                Back
              </WhiteButton>
              <DefaultButton isLoading={isLoading} onClick={handleCreateStrategy} className="w-fit">
                Update
              </DefaultButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Review;
