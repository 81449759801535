import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SelectLanguage from "./SelectLanguage";
import NewStrategy from "./NewStrategy";
import SelectTasks from "./SelectTasks";
import Review from "./Review";
import { useRecoilState } from "recoil";
import { createStrategy, selectedTasks } from "@/store/atoms/createStrategy.atoms";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  data: any;
};

export default function DraftStrategyViewDetailModal(props: Props) {
  const { open, setOpen, refetch, data } = props;
  const [step, setStep] = useState(4);
  const [newStrategy, setNewStrategy] = useRecoilState(createStrategy);
  const [selectedTaskList, setSelectedTaskList] = useRecoilState(selectedTasks);

  console.log("data2323", data);

  useEffect(() => {
    setNewStrategy({
      roadmap: {
        name: data.name,
        description: data.description,
        application_status: data.application_status,
        created_by: data.created_by,
        duration: data.duration,
        language_code: data.language_code,
        one_time_only: data.one_time_only,
        category_id: data.category_id,
      },
      roadmap_stories: data.stories.map((item: any) => {
        return {
          story_id: item.id,
          order: item.order,
          is_skippable: item.is_skippable,
        };
      }),
    });

    setSelectedTaskList(
      data.stories.map((item: any) => {
        return {
          application_status: item.application_status,
          order: item.order,
          partner_roles: item.partner_roles,
          story_id: item.id,
          story_name: item.name,
          total_credit: item?.total_credit,
          total_estimated_time: item?.total_estimated_time,
        };
      })
    );
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-4xl p-6">
                <div className="absolute right-0 top-0  pr-4 pt-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  {step === 1 && <SelectLanguage setStep={setStep} />}
                  {step === 2 && <NewStrategy setStep={setStep} />}
                  {step === 3 && <SelectTasks setStep={setStep} />}
                  {step === 4 && (
                    <Review
                      selectedId={data.id}
                      refetch={refetch}
                      setOpen={setOpen}
                      setStep={setStep}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
