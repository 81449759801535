import { t } from "i18next";
import React from "react";
import TabButton from "./TabButton";

type Props = {
  setCurrentActiveTab: (active: "description" | "taskDetails" | "files") => void;
  activeTab: any;
  state: "active" | "done" | "pending";
};

function TaskDetailTabs(props: Props) {
  const { setCurrentActiveTab, activeTab, state } = props;

  return (
    <div className="border-b mt-7 border-[#C4C4C4]  w-full">
      <div className="grid grid-cols-3 w-full ">
        <TabButton
          active={activeTab === "description"}
          activeState={"description"}
          setActive={setCurrentActiveTab}
          title={t("clientInformation")}
        />
        <TabButton
          active={activeTab === "taskDetails"}
          activeState={"taskDetails"}
          setActive={setCurrentActiveTab}
          title={t("taskDetails")}
        />
        <TabButton
          active={activeTab === "files"}
          activeState={"files"}
          setActive={setCurrentActiveTab}
          title={t("comments")}
        />
      </div>
    </div>
  );
}

export default TaskDetailTabs;
