import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPartnerTaskDetail } from "@/api/partners";
import AssessmentLayout from "@/components/Layout/Assessment";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

const TaskDetailPage = () => {
  const params: any = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    "fetchPartnerTaskDetailToSelect",
    () => fetchPartnerTaskDetail(params?.id),
    {
      retry: false,
      cacheTime: 0,
    }
  );

  if (data?.status === "revised" || data?.status === "review" || data?.status === "inprogress") {
    navigate(`/tasks/active/${params?.id}`);
  } else if (data?.status === "finished") {
    navigate(`/tasks/done/${params?.id}`);
  }

  console.log(data);

  return (
    <>
      {isLoading ? (
        <div>
          <LoadingSpinner size="w-6 h-6" />
        </div>
      ) : (
        <div>Task Detail Page</div>
      )}
    </>
  );
};

export default TaskDetailPage;
