import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import CreateNewStrategyModal from "@/features/strategies/components/CreateNewStrategyModal";
import { PlusIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { useQuery } from "react-query";
import { fetchPartnerRoadmaps } from "@/api/roadmap";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DraftStrategyViewDetailModal from "@/features/strategies/components/DraftStrategyViewDetailModal";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  roadmap: [];
  setSelectedStrategy: (strategy: any) => void;
  setOpenDraftView: (open: boolean) => void;
};

function Stack(props: Props) {
  const { roadmap, setSelectedStrategy, setOpenDraftView } = props;

  console.log("roadmap123123", roadmap);

  return (
    <ul className="divide-y divide-gray-100 pr-5">
      {roadmap?.map((p: any) => (
        <li key={p.application_status} className="flex justify-between gap-x-6 py-5">
          <div className="flex gap-x-4">
            <div className="h-12 w-12 flex-none rounded-full bg-white flex justify-center items-center">
              <PencilSquareIcon
                className="h-7 w-7 text-gray-600 rounded-full bg-gray-100 p-1"
                aria-hidden="true"
              />
            </div>
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900 truncate">{p.name}</p>
              <div className="flex gap-2">
                <p
                  className={`mt-1 flex text-xs leading-5 
                border border-blue-500 rounded-full px-2 py-0.5 first-letter:uppercase
                ${
                  p.application_status === "draft"
                    ? "text-blue-500 border-blue-500"
                    : p.application_status === "approved"
                    ? "text-green-500 border-green-500"
                    : "text-red-500 border-red-500"
                }

                `}>
                  {p.application_status.charAt(0).toUpperCase() + p.application_status.slice(1)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-6">
            <Menu as="div" className="relative flex-none">
              <Menu.Button className="block p-1 text-gray-500 hover:text-gray-900 bg-white rounded-full">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setSelectedStrategy(p);
                          setOpenDraftView(true);
                        }}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900"
                        )}>
                        View
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </li>
      ))}
    </ul>
  );
}

const StrategiesPage = () => {
  const [open, setOpen] = useState(false);
  const [openDraftView, setOpenDraftView] = useState(false);
  const [openWaitingForApprovalView, setOpenWaitingForApprovalView] = useState(false);

  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const { data, isLoading: isLoadingRoadmap, refetch } = useQuery("roadmap", fetchPartnerRoadmaps);

  return (
    <div>
      {isLoadingRoadmap ? (
        <LoadingSpinner size="w-6 h-6" />
      ) : (
        <>
          <div className="flex justify-between">
            <div className="text-2xl font-bold  mb-4">Strategies</div>
            <DefaultButton
              onClick={() => setOpen(true)}
              className="flex items-center justify-center w-fit h-fit">
              <PlusIcon className="w-5 h-5 mr-2" />
              {t("createNew")}
            </DefaultButton>
          </div>
          <div>
            <ul className="divide-y-2 lg:divide-y-0 lg:divide-x-2 divide-gray-200 grid grid-cols-1 lg:grid-cols-3 min-h-[450px] ">
              <div>
                <div className="font-bold text-xl">{t("yourDrafts")}</div>
                <div>
                  <Stack
                    setOpenDraftView={setOpenDraftView}
                    setSelectedStrategy={setSelectedStrategy}
                    roadmap={data?.filter(
                      (p: any) =>
                        p.application_status === "draft" || p.application_status === "rejected"
                    )}
                  />
                </div>
              </div>
              <div className="pt-3 lg:pt-0 lg:pl-3">
                <div className="font-bold text-xl">{t("waitingForApproval")}</div>
                <div>
                  <Stack
                    setOpenDraftView={setOpenDraftView}
                    setSelectedStrategy={setSelectedStrategy}
                    roadmap={data?.filter((p: any) => p.application_status === "approved")}
                  />
                </div>
              </div>
              <div className="pt-3 lg:pt-0 lg:pl-3">
                <div className="font-bold text-xl">{t("onLive")}</div>
              </div>
            </ul>
          </div>

          {open && <CreateNewStrategyModal open={open} setOpen={setOpen} refetch={refetch} />}
          {openDraftView && (
            <DraftStrategyViewDetailModal
              open={openDraftView}
              setOpen={setOpenDraftView}
              refetch={refetch}
              data={selectedStrategy}
            />
          )}
        </>
      )}
    </div>
  );
};

export default StrategiesPage;
