import TaskDetailTabs from "@/components/tabs/taskDetailTabs/index";
import AssessmentLayout from "@/components/Layout/Assessment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  fetchPartnerTaskDetail,
  fetchPartnerTaskTimeline,
  updatePartnerTaskStatus,
} from "@/api/partners";

import Output from "editorjs-react-renderer";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchCountryById } from "@/api/country";
import ToastNotify from "@/components/UI/ToastNotify";

import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { toast } from "react-toastify";
import { convertIsoToDateAndHour } from "@/utils/convertIsoToDate";
import { useTranslation } from "react-i18next";

import PopupModal from "@/components/PopupModal/PopupModal";

const sourceUrl = process.env.REACT_APP_CONTENT_URL;

const CustomParagraphRenderer = ({ data }: any) => {
  const alt = data.file.alt ? data.file.alt : "";
  const src = data.file.url ? sourceUrl + data.file.url : "";
  const width = data.file.width ? data.file.width : "auto";
  const height = data.file.height ? data.file.height : "auto";

  return (
    <div className="my-2">
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  );
};

const CustomListRenderer = ({ data }: any) => {
  return (
    <div className="m-5">
      <ul>
        {data.items.map((item: any, index: any) => (
          <li className="list-disc" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const style = {
  header: {
    textAlign: "left",
    margin: "10px 20px",
  },
  paragraph: {
    fontSize: "16px",
  },
};

const classes = {
  header: "header-class1 header-class2",
  paragraph: "paragraph-class",
};

const TaskDetailPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const renderers = {
    image: CustomParagraphRenderer,
    list: CustomListRenderer,
  };
  const navigate = useNavigate();
  const params: any = useParams();
  const [title, setTitle] = useState("");
  const [active, setActive] = useState<"description" | "taskDetails" | "files">("description");

  const { data, isLoading, isSuccess } = useQuery(
    "fetchPartnerTaskDetail",
    () => fetchPartnerTaskDetail(params?.id),
    {
      retry: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      if (data.status !== "pending") {
        navigate("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { /*  data: timelineData, */ isLoading: isLoadingTimeline } = useQuery(
    "fetchPartnerTaskTimeline",
    () => fetchPartnerTaskTimeline(data?.project_story_id),
    {
      cacheTime: 0,
      retry: false,
      refetchInterval: 5000,
      enabled: !!data?.project_story_id,
    }
  );

  const { data: countryData, isLoading: isLoadingCountry } = useQuery(
    "fetchCountryById",
    () => fetchCountryById(data?.project_countries),
    {
      retry: false,
      enabled: !!data?.project_countries,
    }
  );

  const { mutateAsync, isLoading: isLoadingPartnerStatus } = useMutation(
    "updatePartnerTaskStatus",
    updatePartnerTaskStatus,
    {
      retry: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const name = data.sub_title ? " - " + data.sub_title : "";
      setTitle(data.task_name + name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleDoneStatus = async () => {
    await mutateAsync({
      taskId: params?.id,
      status: "inprogress",
    })
      .then(() => {
        toast.success("Task status updated successfully");
        localStorage.setItem("taskStatus", "active");
        navigate("/");
      })
      .catch(() => {
        toast.error("Error while updating task status");
      });
  };

  const handleDenyStatus = async () => {
    await mutateAsync({
      taskId: params?.id,
      status: "unaccepted",
    })
      .then(() => {
        toast.info("Task has been denied");
        navigate("/");
      })
      .catch(() => {
        toast.error("Error while denying task");
      });
  };

  return (
    <>
      <div className="mt-[30px] font-medium mb-6">
        <Link to="/">
          <span className="text-[#707070]">
            {"<"} {t("backToTasks")}
          </span>
        </Link>
      </div>

      <div className="mb-4 ">
        <div className=" mr-0 bg-white rounded-[10px] p-7 relative">
          <div className="flex justify-end gap-5 items-end absolute right-6 top-6">
            {isLoadingPartnerStatus || isLoading ? (
              <div className="m-4 flex justify-center w-full">
                <LoadingSpinner size="w-5 h-5" />
              </div>
            ) : (
              <>
                <DefaultButton
                  onClick={() => setIsOpen(true)}
                  className="w-fit text-[#4F4999] bg-[#ffffff] hover:bg-transparent hover:text-secondBackground">
                  {t("deny")}
                </DefaultButton>
                <DefaultButton onClick={() => handleDoneStatus()} className="w-fit">
                  {t("accept")}
                </DefaultButton>
              </>
            )}
          </div>
          <div className="flex flex-col">
            <div className="font-medium">{title}</div>
            <TaskDetailTabs setCurrentActiveTab={setActive} activeTab={active} state={"pending"} />
          </div>
          <div className="mt-4 ">
            {isLoading || isLoadingCountry || isLoadingTimeline ? (
              <LoadingSpinner size="w-6 h-6" />
            ) : (
              <>
                {active === "description" && (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-col gap-4 my-4 text-[#263238] text-sm">
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-2 text-xs">{t("status")} :</div>
                          <div className="col-span-6 text-[#263238] flex gap-2 ">
                            <div
                              className={`w-4 h-4 rounded-full`}
                              style={{
                                backgroundColor:
                                  data.status === "pending"
                                    ? "#C42F41"
                                    : data.status === "inprogress"
                                    ? "#60C423"
                                    : "#EF905A",
                              }}></div>
                            <div>{data?.status}</div>
                          </div>
                        </div>
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-2 text-xs">{t("dueDate")}:</div>
                          <div className="col-span-6 text-[#263238]">
                            {convertIsoToDateAndHour(data.due_date)}
                          </div>
                        </div>
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-2 text-xs">{t("clientName")}:</div>
                          <div className="col-span-6 text-[#263238]">{data?.company_name}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("domainName")} :</div>
                          <div className="col-span-6">{data?.project_domain}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("targetCountry")} :</div>
                          <div className="col-span-6 ">{countryData?.name}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("socialMediaAccounts")} :</div>
                          <div className="col-span-6">
                            There is no connected a social media account.
                          </div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("keywords")} :</div>
                          <div className="col-span-6">
                            {data?.project_keywords?.map((element: any, index: any) => {
                              return index === data?.project_keywords?.length - 1
                                ? element + " "
                                : element + ", ";
                            })}
                          </div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("competitors")} :</div>
                          <div className="col-span-6">
                            {data?.project_competitor_domain_names?.map(
                              (element: any, index: any) => {
                                return index === data?.project_competitor_domain_names?.length - 1
                                  ? element + " "
                                  : element + ", ";
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {/*       <div className="grid grid-cols-8">
                        <div className="col-span-2 text-xs">{t("clientNotes")} :</div>
                        <div className="col-span-6 mb-3">
                          <textarea
                            id="message"
                            rows={4}
                            disabled
                            defaultValue={timelineData[0]?.content.message}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "></textarea>
                        </div>
                      </div> */}
                      <div className="grid grid-cols-8">
                        <div className="col-span-2  text-xs">{t("clientOldWorks")} :</div>
                        <div className="col-span-6"></div>
                      </div>
                    </div>
                    <div className="flex flex-col border-t border-gray-200 mt-4">
                      <div className="font-semibold my-4">{t("workDetail")}</div>
                      <div>Here’s the information about task.</div>
                      <Output
                        renderers={renderers}
                        style={style.header}
                        classNames={classes.header}
                        data={JSON.parse(data?.task_content)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <PopupModal closeAction={() => setIsOpen(false)} isPopup={isOpen}>
          <p className="mt-2">
            If you deny this task, you will not be able to reach this task again. Do you approve
            this action?
          </p>
          <div className="flex justify-end">
            <DefaultButton
              onClick={() => setIsOpen(false)}
              className="w-fit text-[#4F4999] bg-[#ffffff] hover:bg-transparent  hover:text-secondBackground">
              {t("cancel")}
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                handleDenyStatus();
                setIsOpen(false);
              }}
              className="w-fit text-[#4F4999] bg-[#ffffff] hover:bg-transparent hover:border-secondBackground border border-transparent hover:text-secondBackground">
              {t("deny")}
            </DefaultButton>
          </div>
        </PopupModal>
        {/*      <div
          className="bg-[#F3F1F9] rounded-[10px] p-[15px] shadow mt-8 mb-10 lg:mt-0 lg:mb-0 lg:fixed top-11 right-12 z-10 w-full lg:w-64"
          style={{
            minHeight: "calc(100vh - (150px))",
          }}>
          {isLoading || isLoadingCountry || isLoadingTimeline ? (
            <LoadingSpinner size="w-6 h-6" />
          ) : (
            <div className="flex flex-col gap-4">
              <div className="rounded-[10px] pt-[15px] font-medium">Task Detail</div>
              <div className="text-sm text-[#263238] flex flex-col gap-3">
                <div className="grid grid-cols-5">
                  <div className="col-span-2 text-xs">Due date :</div>
                  <div className="col-span-3 text-sm">{convertIsoToDateAndHour(data.due_date)}</div>
                </div>
                <div className="grid grid-cols-5 ">
                  <div className="col-span-2 text-xs">Client Name:</div>
                  <div className="col-span-3 truncate text-sm">{data?.company_name}'s project</div>
                </div>
                <div className="grid grid-cols-5 ">
                  <div className="col-span-2 text-xs">Status:</div>
                  <div className="col-span-3 capitalize bg-white rounded-lg w-fit py-1 px-2 flex  gap-2 justify-center items-center text-xs">
                    <div
                      className={`w-4 h-4 rounded-full`}
                      style={{
                        backgroundColor:
                          data.status === "pending"
                            ? "#C42F41"
                            : data.status === "inprogress"
                            ? "#60C423"
                            : "#EF905A",
                      }}></div>
                    <div>{data?.status}</div>
                  </div>
                </div>
                {isLoading || isLoadingTimeline ? (
                  <div className="m-4">
                    <LoadingSpinner size="w-5 h-5" />
                  </div>
                ) : (
                  <div className="h-80"></div>
                )}
              </div>
            </div>
          )}
          <div className="flex justify-between items-end h-full">
            {isLoadingPartnerStatus || isLoading ? (
              <div className="m-4 flex justify-center w-full">
                <LoadingSpinner size="w-5 h-5" />
              </div>
            ) : (
              <>
                {" "}
                <DefaultButton
                  onClick={() => handleDenyStatus()}
                  className="w-fit text-[#4F4999] bg-[#F3F1F9] hover:bg-transparent hover:text-secondBackground">
                  Deny
                </DefaultButton>
                <DefaultButton onClick={() => handleDoneStatus()} className="w-fit">
                  Accept
                </DefaultButton>
              </>
            )}
          </div>
        </div> */}
      </div>
      <ToastNotify />
    </>
  );
};

export default TaskDetailPage;
