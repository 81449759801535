const tr = {
  overview: "Genel Bakış",
  myProfile: "Profilim",
  payments: "Ödemeler",
  logout: "Çıkış",
  english: "İngilizce",
  turkish: "Türkçe",
  newRequest: "Yeni İstek",
  active: "Aktif",
  done: "Tamamlanmış",
  taskName: "Görev Adı",
  status: "Durum",
  dueDate: "Son teslim tarihi",
  clientName: "Müşteri Adı",
  action: "Eylem",
  tasks: "Görevler",
  workDetail: "İş Detayları",
  pending: "Beklemede",
  domainName: "Alan Adı",
  targetCountry: "Hedef Ülke",
  socialMediaAccounts: "Sosyal Medya Hesapları",
  keywords: "Anahtar Kelimeler",
  competitors: "Rakipler",
  clientNotes: "Müşteri Notları",
  clientOldWorks: "Müşterinin Eski Çalışmaları",
  deny: "Reddet",
  accept: "Kabul",
  taskConfirmed: "İş Onaylandı",
  information: "Bilgi",
  activities: "Hareketler",
  personalDetails: "Kişisel Bilgiler",
  jobTitle: "İş Ünvanı",
  role: "Rol",
  finishedProjects: "Tamamlanmış Projeler",
  pendingProjects: "Aktif Projeler",
  unfinishedProjects: "Tamamlanmamış Projeler",
  designer: "Tasarımcı",
  copywriter: "Metin Yazarı",
  workExperience: "Çalışma Deneyimi",
  email: "E-mail",
  password: "Parola",
  forgotYourPassword: "Şifreninizi mi unuttunuz?",
  dontHaveAnAccount: "Hesabınız yok mu?",
  registerNow: "Şimdi kayıt ol",
  accountDetails: "Hesap Detayları",
  profilePhotoEdit: "Profil Fotoğrafını Düzenle",
  chooseAFile: "Bir dosya seç",
  editWorkExperince: "Çalışma Deneyimi Düzenle",
  companyName: "Şirket İsmi",
  startDate: "Başlangıç Tarihi",
  endDate: "Bitiş Tarihi",
  save: "Kaydet",
  cancel: "İptal",
  review: "İncelemede",
  enterYourJobTitle: "İş Ünvanınızı girin",
  enterYourBio: "Hakkınızda bir şeyler yazın",
  sortByStatus: "Duruma göre sırala",
  sortByDate: "Tarihe göre sırala",
  comments: "Yorumlar",
  sendComment: "Yorum Gönder",
  enterComment: "Yorumunuzu girin",
  dragAndDrop: "Dosyalarınızı sürükleyip bırakın ya da",
  browse: "Masaüstünden seçin",
  welcome: "Hoşgeldiniz",
  to: " ",
  enterYourEmail: "E-mail adresinizi girin",
  login: "Giriş",
  signup: "Kayıt Ol",
  fullname: "Ad Soyad",
  enterYourFullName: "Adınızı ve Soyadınızı girin",
  phone: "Telefon",
  enterYourPhone: "Telefon numaranızı girin",
  enterYourPassword: "Parolanızı girin",
  alreadyHaveAnAccount: "Zaten bir hesabınız var mı?",
  backToTasks: "Görevlere dön",
  unreadMessage: "Okunmamış Mesaj",
  unacceptedProjects: "Kabul Edilmemiş Projeler",
  dashboard: "Anasayfa",
  projects: "Projeler",
  allTasks: "Tüm Görevler",
  profile: "Profil",
  signOut: "Çıkış Yap",
  "Works to do": "Yapılacak İşler",
  detail: "Detay",
  search: "Ara",
  clientInformation: "Müşteri Bilgileri",
  taskDetails: "Görev Detayları",
  "companyName/ClientName": "Şirket İsmi/Müşteri İsmi",
  label: "Etiket",
  "View All": "Tümünü Gör",
  "To Do": "Yapılacak",
  "In Review": "İncelemede",
  "View all tasks": "Tüm görevleri gör",
  "Welcome Back": "Tekrar Hoşgeldiniz",
  "Like from clients": "Müşterilerden beğeni",
  "Total Earnings": "Toplam Kazanç",
  "in progress tasks": "devam eden görevler",
  "review tasks": "inceleme görevleri",
  "done tasks": "tamamlanan görevler",
  businessDescription: "İş Tanımı",
  businessName: "İş İsmi",
  businessProvider: "İş Sağlayıcı",
  businessType: "İş Tipi",
  onlineSales: "Online Satış",
  strategies: "Stratejiler",
  yourDrafts: "Taslaklarınız",
  waitingForApproval: "Onay Bekliyor",
  onLive: "Canlı",
  createNew: "Yeni Oluştur",
  "Select Language": "Dil Seçiniz",
  "In which language will you create the strategy?": "Stratejiyi hangi dilde oluşturacaksınız?",
  language: "Dil",
  "Please select language": "Lütfen dil seçiniz",
  "Get started by filling in the information below to create your new strategy.":
    "Yeni stratejinizi oluşturmak için aşağıdaki bilgileri doldurarak başlayın.",
  "Strategy Name": "Strateji İsmi",
  "Strategy Label": "Strateji Etiketi",
  "Select a label": "Bir etiket seçiniz",
  Prerequisite: "Ön Koşul",
  default: "Varsayılan",
  description: "Açıklama",
  back: "Geri",
  next: "İleri",
  "New strategy": "Yeni strateji",
  "Tasks List": "Görevler Listesi",
  "View detail": "Detayları gör",
  "Strategy view": "Strateji görünümü",
  "Here’s your strategy detail. If you think all is done you can click on create button and submit to the approval.":
    "İşte strateji detayınız. Her şeyin tamam olduğunu düşünüyorsanız oluştur butonuna tıklayıp onaya gönderebilirsiniz.",
  create: "Oluştur",
  "Operating time": "İşletme zamanı",
  days: "Günler",
  "Total credits": "Toplam kredi",
  credits: "Kredi",
  amount: "Miktar",
};

export default tr;
