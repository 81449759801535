import React from "react";
import Avatar from "react-avatar-edit";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";

function dataURLtoFile(dataurl: any, filename: any) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class EditableAvatar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const src = "";
    this.state = {
      preview: null,
      src,
      labelStyle: {
        fontSize: "24px",
        cursor: "pointer",
      },
    } as any;
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview: any) {
    this.setState({ preview });
    const file = dataURLtoFile(preview, uuidv4() + ".png");
    this.props.setFile(file);
    this.props.setImage(preview);
  }

  render() {
    return (
      <div className="editor">
        <div className="font-medium text-xl mb-4">
          <>{t("profilePhotoEdit")}</>
        </div>
        <Avatar
          width={380}
          height={255}
          onCrop={this.onCrop}
          onClose={this.onClose}
          src={this.state.src}
          labelStyle={this.state.labelStyle}
        />
        <div className="flex justify-end">
          <button
            onClick={() => this.props.closeModal(false)}
            className="text-secondBackground font-semibold py-2 px-4  mt-4 hover:border-secondBackground border border-transparent transition-all rounded-[10px]">
            Done
          </button>
        </div>
      </div>
    );
  }
}

export default EditableAvatar;
