import Tabs from "@/components/tabs/taskTabs";
import AssessmentLayout from "@/components/Layout/Assessment";
import { useEffect, useState } from "react";
/* import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms"; */
import { useQuery } from "react-query";
import { fetchPartnerTasks } from "@/api/partners";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import SortableTable from "@/components/SortableTable";
import { userAtom } from "@/store/atoms/user.atoms";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import TaskDetailModal from "@/features/tasks/components/TaskDetailModal";

const Overview = () => {
  const user = useRecoilValue(userAtom);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);

  const {
    data: tasks,
    isLoading,
    isError,
    error,
  } = useQuery<any, Error>(["stories", ""], fetchPartnerTasks, {
    retry: false,
    refetchInterval: 5000,
    cacheTime: 0,
  });

  return (
    <div className="flex gap-8">
      <div className="hidden lg:block">
        <div className="bg-white py-8 flex justify-center flex-col h-fit min-w-[320px] shadow rounded space-y-6">
          <div className="flex justify-center flex-col items-center">
            <img
              className="h-20 w-20 rounded-full"
              src={user?.avatar ?? "/assets/icons/placeholder.png"}
              alt=""
            />
            <div className="mt-4  text-sm text-[#4B5563]">{t("Welcome Back")}</div>
            <div className="font-bold text-xl">
              {user?.first_name} {user?.last_name}
            </div>
            <div className="text-[#4B5563] text-sm first-letter:capitalize">
              {user?.profile?.partner_role}
            </div>
          </div>
          <div className="flex justify-center flex-col items-center ">
            <div className="text-[#4F46E5] font-extrabold text-[48px] leading-none">-%</div>
            <div className="text-[#6B7280] text-lg">{t("Like from clients")}</div>
          </div>

          <div className="flex justify-center flex-col items-center">
            <div className="text-[#4F46E5] font-extrabold text-[48px] leading-none">- ₺</div>
            <div className="text-[#6B7280] text-lg">{t("Total Earnings")}</div>
          </div>

          <div className="flex justify-center flex-row items-center text-gray-400 text-lg">
            - - <span>Now</span>
          </div>
        </div>
        <div className="flex flex-col divide-y w-full text-center shadow bg-gray-50">
          <div className="py-3">
            <span className="text-gray-900">- </span>
            <span className="text-gray-600">{t("in progress tasks")}</span>
          </div>
          <div className="py-3">
            <span className="text-gray-900">- </span>
            <span className="text-gray-600">{t("review tasks")}</span>
          </div>
          <div className="py-3">
            <span className="text-gray-900">- </span>
            <span className="text-gray-600">{t("done tasks")}</span>
          </div>
        </div>
      </div>

      <div className=" w-full">
        <div className="mb-6 flex justify-between items-center  w-full">
          <div className="font-bold text-2xl">{t("Works to do")}</div>
          <div className="text-[#4F46E5] font-semibold">
            <Link to="/all-tasks">{t("View all tasks")}</Link>
          </div>
        </div>

        <div>
          <div className="">
            <div className="mt-4">
              <>
                {isLoading ? (
                  <LoadingSpinner size="w-6 h-6" />
                ) : (
                  <div className="flex flex-col gap-4 mb-5">
                    <SortableTable
                      setOpen={setOpen}
                      setSelectedTaskId={setSelectedTaskId}
                      data={
                        tasks
                          ? tasks?.filter(
                              (task: any) => task.status !== "pending" && task.status !== "finished"
                            )
                          : []
                      }
                    />
                  </div>
                )}
                {isError && <div>{error.message}</div>}
              </>
            </div>
          </div>
        </div>
      </div>

      <TaskDetailModal setOpen={setOpen} open={open} id={selectedTaskId as number} />
    </div>
  );
};

export default Overview;
