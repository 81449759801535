import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { fetchPartnerStoryDetail } from "@/api/partners";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

type Props = {
  partnerDetailModal: boolean;
  setPartnerDetailModal: (open: boolean) => void;
  selectedDetailId: number | null;
};

export default function PartnerDetailModal(props: Props) {
  const { partnerDetailModal: open, setPartnerDetailModal: setOpen, selectedDetailId } = props;

  const { data, isLoading } = useQuery(["fetchPartnerDetail", selectedDetailId], () =>
    fetchPartnerStoryDetail(selectedDetailId as number)
  );

  console.log("data123321", data);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="font-semibold text-xl mb-5">Task Detail</div>

                {isLoading ? (
                  <LoadingSpinner size="w-6 h-6" />
                ) : (
                  <>
                    {" "}
                    <div className="flex items-center ">
                      <div className="text-sm space-y-5 w-full">
                        <div className="grid grid-cols-2 gap-5 ">
                          <div>
                            <div className="text-gray-500">Name</div>
                            <div>{data[0]?.name}</div>
                          </div>
                          <div>
                            <div className="text-gray-500">Strategy Label</div>
                            <div>{data[0]?.story_label}</div>
                          </div>
                          <div>
                            <div className="text-gray-500">Operating time</div>
                            <div>{data[0]?.duration} day</div>
                          </div>
                          <div>
                            <div className="text-gray-500">Total credit</div>
                            <div>{data[0]?.total_credit} credits</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="text-gray-500">Description</div>
                            <div>
                              <div>{data[0]?.description}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
