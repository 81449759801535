import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SelectLanguage() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleChange = (value: "en" | "tr") => {
    setLanguage(value);
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
    window.location.reload();
  };

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left z-10 ">
        <div>
          <Menu.Button className="inline-flex items-center gap-1 w-full justify-center rounded-md  py-2 text-sm font-medium text-white   focus:outline-none">
            {language === "en" ? (
              <>
                <img src="/assets/icons/us-flag.svg" alt="en" />
                <span>EN</span>
              </>
            ) : (
              <>
                <img src="/assets/icons/tr-flag.svg" alt="en" />
                <span>TR</span>
              </>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute z-20 right-0 mt-2 w-28 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleChange("en")}
                    className={`${
                      active ? "bg-gray-100" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    <div className="mr-2 ">
                      <UsIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    </div>

                    <span>{t("english")}</span>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleChange("tr")}
                    className={`${
                      active ? "bg-gray-100" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    <div className="mr-2 ">
                      <TrIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    </div>
                    <span>{t("turkish")}</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function UsIcon(props: any) {
  return <img {...props} src="/assets/icons/us-flag.svg" alt="en" />;
}

function TrIcon(props: any) {
  return <img {...props} src="/assets/icons/tr-flag.svg" alt="tr" />;
}
