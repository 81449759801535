import { createStrategy, selectedTasks } from "@/store/atoms/createStrategy.atoms";
import { MinusIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

type Props = {
  selectedStories: any;
  setSelectedStories: any;
  reorderStories: any;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SelectTableTasks = (props: Props) => {
  const { selectedStories, setSelectedStories } = props;
  const [, setSelectedTaskList] = useRecoilState(selectedTasks);

  useEffect(() => {
    setSelectedTaskList(selectedStories);
  }, [selectedStories]);

  return (
    <>
      {selectedStories.map((selectedS: any, index: any) => (
        <tr className={classNames(index % 2 === 0 ? "bg-gray-50" : "bg-white")} key={selectedS.id}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <select
              defaultValue={selectedStories[index].order}
              onChange={(e: any) => {
                const newSelectedStories = selectedStories.map((story: any, i: any) => {
                  if (i === index) {
                    return {
                      ...story,
                      order: +e.target.value,
                    };
                  }
                  return story;
                });

                setSelectedStories(newSelectedStories);
                // reorderStories
              }}
              className="p-2 w-12  border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              {Array.from(Array(selectedStories.length).keys()).map((i) => (
                <option value={+i + 1} key={index}>
                  {+i + 1}
                </option>
              ))}
            </select>
          </td>

          <td className="truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 max-w-[200px]">
            {selectedS.story_name}
          </td>

          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <button
              onClick={() => {
                const newSelectedStories = [...selectedStories];
                newSelectedStories.splice(index, 1);
                setSelectedStories(newSelectedStories);
              }}>
              <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default SelectTableTasks;
