import React, { useState } from "react";
import Input from "@/components/UI/Input";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";

type Props = {
  data: any;
  setShowNewCompany: (show: boolean) => void;
};

const EditWork = (props: Props) => {
  const { data, setShowNewCompany } = props;

  const [, /* companyName */ setCompanyName] = useState("");
  const [, /* role */ setRole] = useState("");
  const [, /* startDate */ setStartDate] = useState("");
  const [, /* endDate */ setEndDate] = useState("");
  /*   const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); */

  return (
    <div className="text-sm flex flex-col gap-4 px-7 pb-5">
      <div className="font-medium text-base">Edit Work Experience</div>
      <div className="flex flex-col gap-4">
        <Input
          placeholder="Company Name"
          name="companyName"
          title="Company Name"
          defaultValue={data.company_name}
          onChange={(event) => setCompanyName(event.target.value)}
        />
        <Input
          placeholder="Role"
          name="role"
          title="Role"
          defaultValue={data.job_title}
          onChange={(event) => setRole(event.target.value)}
        />
        <Input
          placeholder="Start Date"
          name="startDate"
          type="date"
          title="Start Date"
          defaultValue={data.start_date.slice(0, 10)}
          onChange={(event) => setStartDate(event.target.value)}
        />
        <Input
          placeholder="End Date"
          name="endDate"
          title="End Date"
          type="date"
          defaultValue={data.end_date ? data.end_date.slice(0, 10) : ""}
          onChange={(event) => setEndDate(event.target.value)}
        />
      </div>

      <div className="flex justify-between">
        <button className="text-secondBackground" onClick={() => setShowNewCompany(false)}>
          Cancel
        </button>
        <DefaultButton className="w-fit" onClick={() => setShowNewCompany(false)}>
          Save
        </DefaultButton>
      </div>
    </div>
  );
};

export default EditWork;
