import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useQuery } from "react-query";
import { fetchPartnerTasks } from "@/api/partners";
import i18n from "@/i18n";
import { t } from "i18next";
import TaskDetailModal from "./TaskDetailModal";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  status?: string;
}

export default function AllTaskModal(props: Props) {
  const { open, setOpen, status } = props;
  const [detailModal, setDetailModal] = useState(false);
  const currentLanguage = i18n.language;
  const [taskId, setTaskId] = useState<number | null>(null);

  const { data: tasks, isLoading } = useQuery<any, Error>(
    ["fetchTasksAsStatus", status],
    fetchPartnerTasks,
    {
      retry: false,
    }
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="h-[calc(100vh-50px)] relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  sm:w-full sm:max-w-4xl sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                      onClick={() => setOpen(false)}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    {isLoading ? (
                      <div className="w-full h-full">
                        <LoadingSpinner size="w-5 h-5" />
                      </div>
                    ) : (
                      <div className="px-4 sm:px-6 lg:px-8 w-full">
                        <div className="mt-8 flow-root">
                          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">
                                      <>{t("taskName")}</>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                                      <>{t("dueDate")}</>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
                                      <>{t("clientName")}</>
                                    </th>
                                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {tasks?.map((t: any) => (
                                    <tr key={t.id}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {t.task_name}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {new Date(t.due_date).toLocaleDateString(
                                          currentLanguage !== "en" ? "tr-TR" : "en-EN",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                            // hourCycle: "h12",
                                          }
                                        )}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {t.company_name}
                                      </td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <button
                                          className="text-indigo-600 hover:text-indigo-900"
                                          onClick={() => {
                                            setTaskId(t.id);
                                            setDetailModal(true);
                                          }}>
                                          Detail
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {detailModal && <TaskDetailModal open={detailModal} setOpen={setDetailModal} id={taskId} />}
    </>
  );
}
