import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "@/components/Routes/PrivateRoute";
import ProtectedRoute from "@/components/Routes/ProtectedRoute";

import LoginPage from "@/views/Auth/LoginPage";
import RegisterPage from "@/views/Auth/RegisterPage";

import OverviewPage from "@/views/Dashboard/OverviewPage";
import MyProfilePage from "@/views/Dashboard/MyProfilePage";
import PaymentsPage from "@/views/Dashboard/PaymentsPage";
import ActiveDetailPage from "@/views/Dashboard/TaskDetailPage/ActiveDetailPage";
import DoneDetailPage from "@/views/Dashboard/TaskDetailPage/DoneDetailPage";
import PendingDetailPage from "@/views/Dashboard/TaskDetailPage/PendingDetailPage";
import TaskDetailPage from "@/views/Dashboard/TaskDetailPage";
import PageContainer from "@/components/Layout/PageContainer";
import AllTaskPage from "@/views/Dashboard/AllTasksPage";
import ProjectsPage from "@/views/Dashboard/ProjectsPage";
import StrategiesPage from "./views/Dashboard/StrategiesPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route element={<PageContainer />}>
            <Route index element={<OverviewPage />} />

            <Route path="projects" element={<ProjectsPage />} />

            <Route path="all-tasks" element={<AllTaskPage />}></Route>

            <Route path="my-profile">
              <Route index element={<MyProfilePage />} />
            </Route>

            <Route path="payments">
              <Route index element={<PaymentsPage />} />
            </Route>

            <Route path="tasks">
              <Route path=":id" element={<TaskDetailPage />} />

              <Route path="active">
                <Route path=":id" element={<ActiveDetailPage />} />
              </Route>
              <Route path="done">
                <Route path=":id" element={<DoneDetailPage />} />
              </Route>
              <Route path="pending">
                <Route path=":id" element={<PendingDetailPage />} />
              </Route>
            </Route>

            <Route path="strategies">
              <Route index element={<StrategiesPage />} />
            </Route>
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="login" element={<LoginPage />}></Route>
          <Route path="register" element={<RegisterPage />}></Route>
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
