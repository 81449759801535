// Package
import { atom } from "recoil";

// Interface
import { UserType, ProjectsType } from "@/models/user.interface";

const userAtom = atom<UserType | undefined>({
  key: "userAtom", // unique ID (with respect to other atoms/selectors)
  default: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as string)
    : undefined, // default value (aka initial value)
});

const projectsAtom = atom<ProjectsType>({
  key: "projectsAtom", // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
});

const isChangeProjectAtom = atom({
  key: "isChangeProjectAtom", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export { userAtom, projectsAtom, isChangeProjectAtom };
