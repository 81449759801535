import client from "./client";

export const fetchRoadmapCategories = async () => {
  const response = await client.get(`/roadmaps/categories`);
  return response.data;
};

export const fetchPartnerRoadmaps = async () => {
  const response = await client.get(`/partner/roadmap`);
  return response.data;
};

export const postRoadmap = async (data: any) => {
  const response = await client.post(`/partner/roadmap`, data);
  return response.data;
};

export const putRoadmap = async (data: any) => {
  const response = await client.put(`/partner/roadmap`, data);
  return response.data;
};
