export const convertUTCDateToLocalDate = (date: Date | string): Date => {
  const tempDate = typeof date === "string" ? new Date(date) : date;
  const newDate = new Date(tempDate.getTime()+tempDate.getTimezoneOffset()*60*1000);

  const offset = tempDate.getTimezoneOffset() / 60;
  const hours = tempDate.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}