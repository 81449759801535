import { fetchPartnerStory } from "@/api/partners";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import WhiteButton from "@/components/buttons/WhiteButton";
import { MinusIcon } from "@heroicons/react/20/solid";
import { CheckIcon, EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import SelectTableTasks from "./SelectTableTasks";
import { useRecoilState } from "recoil";
import { createStrategy, selectedTasks } from "@/store/atoms/createStrategy.atoms";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { userAtom } from "@/store/atoms/user.atoms";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";
import PartnerDetailModal from "./PartnerDetailModal";
import { t } from "i18next";

const steps = [
  { name: "Step 1", href: "#", status: "complete" },
  { name: "Step 2", href: "#", status: "complete" },
  { name: "Step 3", href: "#", status: "current" },
  { name: "Step 4", href: "#", status: "upcoming" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
type Props = {
  setStep: (step: number) => void;
};

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
];

const SelectTasks = (props: Props) => {
  const { setStep } = props;

  const { data, isLoading: isLoadingStories } = useQuery("partnerStories", fetchPartnerStory);

  const [partnerDetailModal, setPartnerDetailModal] = useState(false);
  const [selectedDetailId, setSelectedDetailId] = useState<number | null>(null);

  const [selectedTaskList] = useRecoilState(selectedTasks);
  const [selectedStories, setSelectedStories] = useState<any[]>(selectedTaskList);
  const [newStrategy, setNewStrategy] = useRecoilState(createStrategy);
  const [user] = useRecoilState(userAtom);

  const reorderStories = () => {
    const newSelectedStories = [...selectedStories];
    newSelectedStories.sort((a: any, b: any) => a.order - b.order);
    setSelectedStories(newSelectedStories);

    if (selectedStories.length > 0) {
      const output = selectedStories.map((s: any) => ({
        story_id: s.story_id,
        order: s.order,
        is_skippable: false,
      }));

      setNewStrategy({
        roadmap: newStrategy.roadmap,
        roadmap_stories: output,
      });
    }
  };

  const handleNextStep = () => {
    reorderStories();
    setNewStrategy({
      roadmap: { ...newStrategy.roadmap, created_by: user?.id as number },
      roadmap_stories: selectedStories,
    });

    if (selectedStories.length === 0) {
      toast.error("Please select at least one task");
      return;
    }

    setStep(4);
  };

  return (
    <div>
      {partnerDetailModal && (
        <PartnerDetailModal
          partnerDetailModal={partnerDetailModal}
          setPartnerDetailModal={setPartnerDetailModal}
          selectedDetailId={selectedDetailId}
        />
      )}
      <>
        <div className="pb-7">
          <div className="font-medium text-lg">{t("New strategy")}</div>
          <div className="text-gray-500 text-sm">
            {t("Get started by filling in the information below to create your new strategy.")}
          </div>
        </div>

        {isLoadingStories ? (
          <LoadingSpinner size="w-6 h-6" />
        ) : (
          <>
            <div className="grid lg:grid-cols-2 border-y py-8 gap-5">
              <div>
                <div className="flex justify-between">
                  <div>{t("tasks")}</div>
                  <button
                    type="button"
                    className="rounded-full h-fit bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex border-t mt-4 pt-6">
                  <div className="text-sm w-24 mr-4">{t("Tasks List")}</div>
                  <div className="flow-root w-full">
                    <div className="overflow-x-hidden  py-1">
                      <div className="inline-block min-w-full  align-middle px-1">
                        <div className="overflow-y-auto h-80  w-full lg:w-96 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                          <table className="w-full divide-y divide-gray-300 ">
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <SelectTableTasks
                                selectedStories={selectedStories}
                                setSelectedStories={setSelectedStories}
                                reorderStories={reorderStories}
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flow-root">
                  <div className="overflow-x-hidden  py-1">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                      <div className="overflow-auto  h-96  shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col"></th>
                              <th className="py-3  text-sm ">Mikrolo {t("Tasks List")}</th>
                              <th></th>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {data
                              .filter((story: any) => {
                                return !selectedStories.find(
                                  (selectedS) => selectedS.story_id === story.story_id
                                );
                              })
                              .map((story: any) => (
                                <tr key={story.story_id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <button
                                      onClick={() => {
                                        setSelectedStories([
                                          ...selectedStories,
                                          { ...story, order: selectedStories.length + 1 },
                                        ]);
                                      }}>
                                      <PlusIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </td>
                                  <td className=" text-sm font-medium text-gray-900 w-full">
                                    {story.story_name}
                                  </td>

                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Menu as="div" className="relative flex-none">
                                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                        <span className="sr-only">Open options</span>
                                        <EllipsisVerticalIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                onClick={() => {
                                                  setSelectedDetailId(story.story_id);
                                                  setPartnerDetailModal(true);
                                                }}
                                                className={classNames(
                                                  active ? "bg-gray-50" : "",
                                                  "text-left block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                                                )}>
                                                {t("View detail")}
                                              </div>
                                            )}
                                          </Menu.Item>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center py-5">
              <nav>
                <ol className="flex items-center">
                  {steps.map((step, stepIdx) => (
                    <li
                      key={step.name}
                      className={classNames(
                        stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                        "relative"
                      )}>
                      {step.status === "complete" ? (
                        <>
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-indigo-600" />
                          </div>
                          <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 ">
                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            <span className="sr-only">{step.name}</span>
                          </div>
                        </>
                      ) : step.status === "current" ? (
                        <>
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-gray-200" />
                          </div>
                          <div
                            className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                            aria-current="step">
                            <span
                              className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                              aria-hidden="true"
                            />
                            <span className="sr-only">{step.name}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-gray-200" />
                          </div>
                          <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                            <span className="sr-only">{step.name}</span>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>

            <div className="flex gap-4 justify-end mt-4">
              <WhiteButton
                onClick={() => {
                  setStep(2);
                }}
                className="w-fit">
                {t("back")}
              </WhiteButton>
              <DefaultButton className="w-fit" onClick={handleNextStep}>
                {t("next")}
              </DefaultButton>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default SelectTasks;
