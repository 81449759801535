import { QueryFunctionContext } from "react-query";
import client from "./client";

export const fetchPartnerTasks = async ({ queryKey }: QueryFunctionContext) => {
  if (queryKey[1] === "") {
    const response = await client.get("/partner/tasks");
    return response.data;
  } else {
    const response = await client.get(`/partner/tasks?status=${queryKey[1]}`);
    return response.data;
  }
};

export const fetchPartnerTaskDetail = async (taskId: number) => {
  const response = await client.get(`/partner/tasks/${taskId}`);
  return response.data;
};

export const fetchPartnerTaskTimeline = async (projectStoryId: number) => {
  const response = await client.get(`/stories/${projectStoryId}/messages`);
  return response.data;
};

export const postPartnerTaskTimeline = async (data: {
  partner_task_id: number;
  message: string;
  is_work: boolean;
  files: File[];
}) => {
  const form_data: any = new FormData();

  if (data.files && data.files.length > 0) {
    for (let i = 0; i < data.files.length; i++) {
      // add each file to form data
      form_data.append(`files`, data.files[i]);
    }
  }

  data.message && form_data.append("message", data.message);
  data.is_work && form_data.append("is_work", data.is_work);

  const response = await client.post(`/partner/tasks/${data.partner_task_id}/timeline`, form_data);
  return response.data;
};

export const fetchPartnerWorkExperience = async () => {
  const response = await client.get("/partner/work-experience");
  return response.data;
};

export const postPartnerWorkExperience = async (data: {
  company_name: string;
  job_title: string;
  start_date: string;
  end_date: string | null;
}) => {
  const response = await client.post("/partner/work-experience", data);
  return response.data;
};

export const updatePartnerTaskStatus = async (data: { taskId: number; status: string }) => {
  const response = await client.put(`/partner/tasks/${data.taskId}/status?status=${data.status}`);
  return response.data;
};

export const fetchPartnerRole = async () => {
  const response = await client.get("/partner/roles");
  return response.data;
};

export const fetchPartnerStory = async () => {
  const response = await client.get("/partner/story");
  return response.data;
};

export const fetchPartnerStoryDetail = async (storyId: number) => {
  const response = await client.get(`/partner/story/${storyId}`);
  return response.data;
};

export const fetchRoadmapApplicationHistory = async (roadmapId: number | null) => {
  const response = await client.get(`/partner/roadmap/${roadmapId}/application_history`);
  return response.data;
};
