import { convertIsoToDate } from "@/utils/convertIsoToDate";
import React from "react";

type Props = {
  data: {
    company_name: string;
    job_title: string;
    start_date: string;
    end_date: string;
    id: number;
  };
  setEditCompany: (show: boolean) => void;
  setEditData: (data: any) => void;
};

const Work = (props: Props) => {
  const { data, setEditCompany, setEditData } = props;

  const handleClick = () => {
    console.log("handleClick");
    setEditCompany(true);
    setEditData(data);
  };

  return (
    <div className="text-sm flex flex-col gap-2 rounded p-2 border shadow">
      <div className="flex justify-between">
        <div className="font-medium">{data.company_name}</div>
        <button onClick={handleClick}>
          <img src="/assets/icons/edit.svg" alt="" width={20} />
        </button>
      </div>
      <div>
        <div>{data.job_title}</div>
        <div>
          {convertIsoToDate(data.start_date)} -{" "}
          {data.end_date ? convertIsoToDate(data.end_date) : "Present"}
        </div>
      </div>
    </div>
  );
};

export default Work;
