import { convertIsoToDateAndHour } from "@/utils/convertIsoToDate";
import { t } from "i18next";
import Output from "editorjs-react-renderer";
import { useQuery } from "react-query";
import { fetchCountryById } from "@/api/country";

type Props = {
  data: any;
};

const sourceUrl = process.env.REACT_APP_CONTENT_URL;

const CustomParagraphRenderer = ({ data }: any) => {
  const alt = data.file.alt ? data.file.alt : "";
  const src = data.file.url ? sourceUrl + data.file.url : "";
  const width = data.file.width ? data.file.width : "auto";
  const height = data.file.height ? data.file.height : "auto";

  return (
    <div className="my-2">
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  );
};

const CustomListRenderer = ({ data }: any) => {
  return (
    <div className="m-5">
      <ul>
        {data.items.map((item: any, index: any) => (
          <li className="list-disc" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const style = {
  header: {
    textAlign: "left",
    margin: "10px 20px",
  },
  paragraph: {
    fontSize: "16px",
  },
};

const classes = {
  header: "header-class1 header-class2",
  paragraph: "paragraph-class",
};

const Details = (props: Props) => {
  const { data } = props;

  const renderers = {
    image: CustomParagraphRenderer,
    list: CustomListRenderer,
  };

  const { data: countryData, isLoading: isLoadingCountry } = useQuery(
    "fetchCountryById",
    () => fetchCountryById(data?.project_countries),
    {
      retry: false,
      enabled: !!data?.project_countries,
    }
  );

  console.log("data21", data?.task_content);

  return (
    <div>
      <div className={`flex flex-col flex-1`}>
        <div className="grid grid-cols-2 items-center gap-6 my-4 text-[#263238] text-sm">
          <div className="flex flex-col">
            <div className="">{t("status")} :</div>
            <div className=" text-black flex gap-2 ">
              <div>{data?.status}</div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="">{t("dueDate")}:</div>
            <div className="text-black">{convertIsoToDateAndHour(data?.due_date)}</div>
          </div>
          <div className="flex flex-col">
            <div className="">{t("label")}:</div>
            <div className="text-black">
              {data?.users?.map((item: any, index: any) => {
                return (
                  <span>
                    {item.first_name}
                    {index !== data?.users?.length - 1 && ","}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="">{t("clientName")}:</div>
            <div className="text-black">{data?.company_name}</div>
          </div>
          <div className="flex flex-col">
            <div className="">{t("amount")}:</div>
            <div className="text-black">{data?.amount}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col border-t border-gray-200 mt-4">
        <div className="font-semibold my-4">{t("workDetail")}</div>
        <div>{data?.sub_content}</div>
        {/*  <Output
          renderers={renderers}
          style={style.header}
          classNames={classes.header}
          data={data?.task_content && JSON.parse(data?.task_content)}
        /> */}
      </div>
    </div>
  );
};

export default Details;
