import Work from "./Work";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import AddNewCompany from "./AddNewCompany";
import EditWork from "@/components/WorkExperienceModal/Work/EditWork";

interface Props {
  children?: React.ReactNode;
  modalState: boolean;
  setModalState: (state: boolean) => void;
  workData: any;
  refetch: () => void;
}

export default function WorkExperienceModal(props: Props) {
  const { modalState, setModalState } = props;
  const [showNewCompany, setShowNewCompany] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editData, setEditData] = useState<{
    company_name: string;
    job_title: string;
    start_date: string;
    end_date: string;
    id: number;
  }>();

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    setModalState(false);
  }

  useEffect(() => {
    setIsOpen(modalState);
  }, [modalState]);

  return (
    <div className="">
      {modalState && (
        <div className="fixed top-0 left-0 bg-gray-300 h-screen w-screen z-5 transition-all opacity-50"></div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto z-6 text-center "
          onClose={closeModal}>
          <div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-md p-4 my-8 overflow-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 w-full flex justify-end">
                  <button
                    type="button"
                    className="px-2 font-medium text-blue-900 border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 transition-all"
                    onClick={closeModal}>
                    {"x"}
                  </button>
                </Dialog.Title>
                {!showNewCompany ? (
                  !editCompany ? (
                    <div className="px-5 flex flex-col gap-[30px]">
                      <div className="text-base font-medium text-black flex justify-between relative items-center mt-2">
                        <div>Work Experience</div>
                        <button
                          className="flex gap-2 text-xs"
                          onClick={() => setShowNewCompany(true)}>
                          <span>+</span>
                          <div>Add new</div>
                        </button>
                      </div>
                      <div className="flex flex-col gap-8">
                        {props.workData.map((item: any) => (
                          <Work
                            key={item.id}
                            data={item}
                            setEditCompany={setEditCompany}
                            setEditData={setEditData}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <EditWork setShowNewCompany={setEditCompany} data={editData} />
                  )
                ) : (
                  <AddNewCompany setShowNewCompany={setShowNewCompany} refetch={props.refetch} />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
