import React from "react";
import { convertIsoToDateAndHour } from "@/utils/convertIsoToDate";
import NavigateDetail from "./NavigateDetail";
import { useTranslation } from "react-i18next";

const useSortableData = (items: any, config = null) => {
  // get current language

  const [sortConfig, setSortConfig] = React.useState<any>(config);

  const sortedItems = React.useMemo(() => {
    const sortableItems: any = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a: any, b: any) => {
        if (sortConfig.key === "dueDate") {
          if (new Date(a[sortConfig.key]).getTime() < new Date(b[sortConfig.key]).getTime()) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (new Date(a[sortConfig.key]).getTime() > new Date(b[sortConfig.key]).getTime()) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: any) => {
    let direction = "descending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "descending") {
      direction = "ascending";
    }

    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const ProductTable = (props: any) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const colors = {
    revised: "#008000",
    inprogress: "#a8a805",
    review: "#0c94ca",
    pending: "#FF0000",
    finished: "#EF905A",
  };

  const { items, requestSort, sortConfig } = useSortableData(props.products);

  const getClassNamesFor = (name: any) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <div className="overflow-x-auto relative">
      <table className="w-full text-sm text-left text-gray-500 border rounded-lg overflow-hidden">
        <thead className="text-xs font-medium text-gray-400  bg-gray-50 uppercase">
          <tr>
            <th scope="col" className="py-3 px-6 ">
              {t("taskName")}
            </th>

            <th scope="col" className="py-3 px-6 ">
              <div onClick={() => requestSort("dueDate")} className={getClassNamesFor("price")}>
                <div className="flex gap-2 group relative">
                  <span>{t("dueDate")}</span>
                  <img src="/assets/icons/sort-solid.svg" alt="switch" width={10} />
                  <div className="bg-white p-2 group-hover:block hidden absolute w-28 -right-32 z-50 shadow">
                    <>{t("sortByDate")}</>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" className="py-3 px-6">
              <div className={getClassNamesFor("stock")}>
                <div className="flex gap-2">
                  <span>{t("clientName")}</span>
                </div>
              </div>
            </th>
            <th scope="col" className="py-3 px-2 text-transparent">
              <button type="button">
                <span>{t("action")}</span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.slice(0, 8).map((item: any, index: any) => (
            <NavigateDetail id={item.id} state={item.status} key={index}>
              <td className="py-4 px-6 text-gray-900 font-medium flex relative">
                <div>{item.name}</div>
                {item.messageCount > 0 && (
                  <div className="flex items-center justify-center rounded-full w-4 h-4 text-white bg-red-400 text-xs absolute left-3 top-1">
                    {item.messageCount}
                  </div>
                )}
              </td>
              {/*   <td
                className={`py-4 px-6  font-medium capitalize`}
                style={{
                  color: colors[item.status as keyof typeof colors],
                }}>
                {item.progress}
              </td> */}
              <td className="py-4 px-6">
                {/* {item.dueDate}{" "} */}
                {new Date(item.dueDate).toLocaleDateString(
                  currentLanguage !== "en" ? "tr-TR" : "en-EN",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    // hourCycle: "h12",
                  }
                )}
              </td>
              <td className="py-4 px-6">{item.clientName}</td>
              <td
                onClick={() => {
                  props.setSelectedTaskId(item.id);
                  props.setOpen(true);
                }}
                className="py-2 pl-2 pr-6 text-indigo-500 mr-2">
                {t("detail")}
              </td>
            </NavigateDetail>
          ))}
        </tbody>
      </table>
    </div>
  );
};

type Props = {
  data: any;
  setSelectedTaskId: any;
  setOpen: any;
};

export default function SortableTable(props: Props) {
  const { data, setSelectedTaskId } = props;
  const productData = data.map((item: any) => {
    return {
      id: item.id,
      name: item.task_name + " " + (item.sub_title ? "- " + item.sub_title : ""),
      status: item.status,
      progress:
        item.status === "pending"
          ? "new request"
          : item.status === "inprogress"
          ? "Active"
          : item.status,
      dueDate: convertIsoToDateAndHour(item.due_date),
      clientName: item.company_name,
      messageCount: item.unread_message_count,
    };
  });

  return (
    <div>
      <ProductTable
        setSelectedTaskId={setSelectedTaskId}
        products={productData}
        allProduct={data}
        setOpen={props.setOpen}
      />
    </div>
  );
}
