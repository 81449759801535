import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SelectLanguage from "./SelectLanguage";
import NewStrategy from "./NewStrategy";
import SelectTasks from "./SelectTasks";
import Review from "./Review";
import { createStrategy, selectedTasks } from "@/store/atoms/createStrategy.atoms";
import { useRecoilState } from "recoil";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
};

export default function CreateNewStrategyModal(props: Props) {
  const { open, setOpen, refetch } = props;
  const [step, setStep] = useState(1);
  const [newStrategy, setNewStrategy] = useRecoilState(createStrategy);
  const [selectedTaskList, setSelectedTaskList] = useRecoilState(selectedTasks);

  useEffect(() => {
    setNewStrategy({
      roadmap: {
        name: "",
        language_code: "",
        category_id: 0,
        description: "",
        rules: [],
        duration: 0,
        // prerequisites: null,
        one_time_only: false,
        application_status: "draft",
        created_by: 0,
      },
      roadmap_stories: [
        {
          story_id: 0,
          order: 0,
          is_skippable: false,
        },
      ],
    });

    setSelectedTaskList([]);
  }, [setNewStrategy, setSelectedTaskList]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-4xl p-6">
                <div className="absolute right-0 top-0  pr-4 pt-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  {step === 1 && <SelectLanguage setStep={setStep} />}
                  {step === 2 && <NewStrategy setStep={setStep} />}
                  {step === 3 && <SelectTasks setStep={setStep} />}
                  {step === 4 && <Review refetch={refetch} setOpen={setOpen} setStep={setStep} />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
