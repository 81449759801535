export const convertIsoToDate = (iso: string) => {
  const date = new Date(iso);
  return date.toLocaleDateString("tr-TR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const convertIsoToDateAndHour = (iso: string) => {
  const date = new Date(iso);
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    ", " +
    (date.getHours() + 3) +
    ":" +
    date.getMinutes()
  );
};
