import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { fetchPartnerTasks } from "@/api/partners";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { BellIcon } from "@heroicons/react/24/outline";
import TaskDetailModal from "@/features/tasks/components/TaskDetailModal";

type Props = {
  setUnreadMessageCount: (count: number) => void;
};

export default function Notification(props: Props) {
  const { setUnreadMessageCount } = props;
  const {
    data: tasks,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useQuery<any, Error>(["notificationStories", ""], fetchPartnerTasks, {
    retry: false,
    refetchInterval: 5000,
    cacheTime: 0,
  });
  const [detailModal, setDetailModal] = useState(false);
  const [taskId, setTaskId] = useState<number | null>(null);

  useEffect(() => {
    if (isSuccess) {
      setUnreadMessageCount(
        tasks?.filter((item: any) => item.unread_message_count > 0).length || 0
      );
    }
  }, [isSuccess, setUnreadMessageCount, tasks]);

  return (
    <div className=" top-16 max-w-sm px-4 z-[998] ">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button>
              <span>
                <BellIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute left-1/2 z-[999] mt-3 w-60 lg:w-72 max-w-xs -translate-x-full transform px-4 sm:px-0 lg:max-w-3xl bg-[#F5F5F5]">
                <div className="overflow-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 h-[500px]">
                  <div className="flex flex-col gap-2 p-4">
                    {isLoading ? (
                      <>
                        <LoadingSpinner size="w-5 h-5" />
                      </>
                    ) : (
                      <>
                        {tasks &&
                          tasks.length > 0 &&
                          tasks
                            .filter((item: any) => item.unread_message_count > 0)
                            .map((item: any) => (
                              <div
                                key={item.id}
                                onClick={() => {
                                  setTaskId(item.id);
                                  setDetailModal(true);
                                }}>
                                <div className="bg-white p-2 cursor-pointer text-sm shadow rounded-lg font-semibold ">
                                  {item.task_name} - {item.story_name}
                                  <div className="font-normal italic text-xs">
                                    {item.unread_message_count} {t("unreadMessage")}
                                  </div>
                                </div>
                              </div>
                            ))}
                      </>
                    )}
                    {isError && (
                      <>
                        <span>{error.message}</span>
                      </>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

      {detailModal && <TaskDetailModal open={detailModal} setOpen={setDetailModal} id={taskId} />}
    </div>
  );
}
