interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  value?: string;
  title: string;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
}

const Input = (props: Props) => {
  const { placeholder, onChange, name, value, title, type, required } = props;
  return (
    <div className="w-full flex flex-col gap-3">
      <label htmlFor={name} className="text-textColor text-sm">
        {title}
      </label>
      <input
        {...props}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className="w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
        name={name}
        id={name}
        type={type}
        required={required}
      />
    </div>
  );
};

export default Input;
