const en = {
  overview: "Overview",
  myProfile: "My Profile",
  payments: "Payments",
  logout: "Log out",
  english: "English",
  turkish: "Turkish",
  newRequest: "New Request",
  active: "Active",
  done: "Done",
  taskName: "Task Name",
  status: "Status",
  dueDate: "Due Date",
  clientName: "Client Name",
  action: "Action",
  tasks: "Tasks",
  workDetail: "Work Details",
  pending: "Pending",
  domainName: "Domain Name",
  targetCountry: "Target Country",
  socialMediaAccounts: "Social Media Accounts",
  keywords: "Keywords",
  competitors: "Competitors",
  clientNotes: "Client Notes",
  clientOldWorks: "Client Old Works",
  deny: "Deny",
  accept: "Accept",
  taskConfirmed: "Task Confirmed",
  information: "Information",
  activities: "Activities",
  personalDetails: "Personal Details",
  jobTitle: "Job Title",
  role: "Role",
  finishedProjects: "Finished Projects",
  pendingProjects: "Active Projects",
  unfinishedProjects: "Unfinished Projects",
  designer: "Designer",
  copywriter: "Copywriter",
  workExperience: "Work Experience",
  email: "E-mail",
  password: "Password",
  forgotYourPassword: "Forgot your password?",
  dontHaveAnAccount: "Don't have an account?",
  registerNow: "Register now",
  accountDetails: "Account Details",
  profilePhotoEdit: "Profile Photo Edit",
  chooseAFile: "Choose a file",
  editWorkExperince: "Edit Work Experience",
  companyName: "Company Name",
  startDate: "Start Date",
  endDate: "End Date",
  save: "Save",
  cancel: "Cancel",
  review: "Review",
  enterYourJobTitle: "Enter your job title",
  enterYourBio: "Enter your bio",
  sortByStatus: "Sort by status",
  sortByDate: "Sort by date",
  comments: "Comments",
  addComment: "Add Comment",
  enterComment: "Add comment",
  dragAndDrop: "Drag & Drop your files or",
  browse: "Browse",
  welcome: "Welcome",
  to: "to",
  enterYourEmail: "Enter your email",
  login: "Login",
  signup: "Sign Up",
  fullname: "Full Name",
  enterYourFullName: "Enter your full name",
  phone: "Phone",
  enterYourPhone: "Enter your phone",
  enterYourPassword: "Enter your password",
  alreadyHaveAnAccount: "Already have an account?",
  sendComment: "Send Comment",
  backToTasks: "Back to Tasks",
  unreadMessage: "Unread Message",
  unacceptedProjects: "Unaccepted Projects",
  dashboard: "Dashboard",
  projects: "Projects",
  allTasks: "All Tasks",
  profile: "Profile",
  signOut: "Sign Out",
  "Works to do": "Works to do",
  detail: "Detail",
  search: "Search",
  clientInformation: "Client Information",
  taskDetails: "Task Details",
  "companyName/ClientName": "Company Name/Client Name",
  label: "Label",
  "View All": "View All",
  "To Do": "To Do",
  "In Review": "In Review",
  "View all tasks": "View all tasks",
  "Welcome Back": "Welcome Back",
  "Like from clients": "Like from clients",
  "Total Earnings": "Total Earnings",
  "in progress tasks": "in progress tasks",
  "review tasks": "review tasks",
  "done tasks": "done tasks",
  businessDescription: "Business Description",
  businessName: "Business Name",
  businessProvider: "Business Provider",
  businessType: "Business Type",
  onlineSales: "Online Sales",
  strategies: "Strategies",
  yourDrafts: "Your Drafts",
  waitingForApproval: "Waiting for Approval",
  onLive: "On Live",
  createNew: "Create New",
  "Select Language": "Select Language",
  "In which language will you create the strategy?":
    "In which language will you create the strategy?",
  language: "Language",
  "Please select language": "Please select language",
  "Get started by filling in the information below to create your new strategy.":
    "Get started by filling in the information below to create your new strategy.",
  "Strategy name": "Strategy name",
  "Strategy Label": "Strategy Label",
  "Select a label": "Select a label",
  Prerequisite: "Prerequisite",
  default: "Default",
  description: "Description",
  next: "Next",
  back: "Back",
  "New strategy": "New strategy",
  "Tasks List": "Tasks List",
  "View detail": "View detail",
  "Strategy view": "Strategy view",
  "Here’s your strategy detail. If you think all is done you can click on create button and submit to the approval.":
    "Here’s your strategy detail. If you think all is done you can click on create button and submit to the approval.",
  create: "Create",
  "Strategy Name": "Strategy Name",
  "Operating time": "Operating time",
  days: "days",
  "Total credits": "Total credits",
  credits: "credits",
  amount: "Amount",
};

export default en;
