type ModelType = {
  closeAction: React.MouseEventHandler;
  title?: string;
  children: React.ReactNode;
  isPopup: boolean;
};

const PopupModal = ({ closeAction, title, children, isPopup }: ModelType) => {
  return isPopup ? (
    <>
      <div className="flex justify-center items-center fixed inset-0 z-[99]">
        <button
          className="fixed inset-0 bg-black opacity-25 z-20"
          onClick={closeAction}
          type="button"></button>
        <div className="flex flex-col gap-6 bg-white p-6 rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full relative z-30">
          <div className="flex justify-end items-start -mb-6">
            <button onClick={closeAction}>
              <img src="/assets/icons/close.svg" alt="" width={20} />
            </button>
          </div>
          {title && <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>}
          {children}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default PopupModal;
