interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  children: React.ReactNode;
  className?: string;
}

const ValidationInput = (props: Props) => {
  const { name, title } = props;
  return (
    <div className="w-full flex flex-col gap-2">
      <label htmlFor={name} className="text-textColor text-sm">
        {title}
      </label>
      {props.children}
    </div>
  );
};

export default ValidationInput;
