import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

import TaskDetailTabs from "@/components/tabs/taskDetailTabs/index";
import AssessmentLayout from "@/components/Layout/Assessment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { fetchPartnerTaskDetail, fetchPartnerTaskTimeline } from "@/api/partners";

import { userAtom } from "@/store/atoms/user.atoms";

import Output from "editorjs-react-renderer";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchCountryById } from "@/api/country";

import { convertIsoToDateAndHour } from "@/utils/convertIsoToDate";
import { useTranslation } from "react-i18next";
import { convertUTCDateToLocalDate } from "@/utils/convertUTCDateToLocalDate";

const systemMessages = ["system", "notification", "auto_message"];

const sourceUrl = process.env.REACT_APP_CONTENT_URL;

const CustomParagraphRenderer = ({ data }: any) => {
  const alt = data.file.alt ? data.file.alt : "";
  const src = data.file.url ? sourceUrl + data.file.url : "";
  const width = data.file.width ? data.file.width : "auto";
  const height = data.file.height ? data.file.height : "auto";

  return (
    <div className="my-2">
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  );
};

const CustomListRenderer = ({ data }: any) => {
  return (
    <div className="m-5">
      <ul>
        {data.items.map((item: any, index: any) => (
          <li className="list-disc" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const style = {
  header: {
    textAlign: "left",
    margin: "10px 20px",
  },
  paragraph: {
    fontSize: "16px",
  },
};

const classes = {
  header: "header-class1 header-class2",
  paragraph: "paragraph-class",
};

const controlSystemMessage = (
  text: string,
  clientName: string,
  isYourMessage = false,
  userRole = ""
) => {
  if (systemMessages.includes(text)) {
    return <span className="capitalize">System</span>;
  }

  if (isYourMessage) {
    return <span>You</span>;
  } else {
    return (
      <span>
        {clientName}
        {userRole !== "partner_user" ? " (Customer)" : " (Partner)"}
      </span>
    );
  }
};

const TaskDetailPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const renderers = {
    image: CustomParagraphRenderer,
    list: CustomListRenderer,
  };

  const [user] = useRecoilState(userAtom);

  const params: any = useParams();
  const [title, setTitle] = useState("");
  const [active, setActive] = useState<"description" | "taskDetails" | "files">("files");

  const { data, isLoading, isSuccess } = useQuery(
    "fetchPartnerTaskDetail",
    () => fetchPartnerTaskDetail(params?.id),
    {
      retry: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      if (data.status !== "finished") {
        navigate("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { data: timelineData, isLoading: isLoadingTimeline } = useQuery(
    "fetchPartnerTaskTimeline",
    () => fetchPartnerTaskTimeline(data?.project_story_id),
    {
      cacheTime: 0,
      retry: false,
      refetchInterval: 5000,
      enabled: !!data?.project_story_id,
    }
  );

  const { data: countryData, isLoading: isLoadingCountry } = useQuery(
    "fetchCountryById",
    () => fetchCountryById(data?.project_countries),
    {
      retry: false,
      enabled: !!data?.project_countries,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const name = data.sub_title ? " - " + data.sub_title : "";
      setTitle(data.task_name + name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    const firstTimeout = setTimeout(() => {
      setActive("description");
    }, 100);
    const secondTimeout = setTimeout(() => {
      setActive("files");
    }, 100);

    return () => {
      clearTimeout(firstTimeout);
      clearTimeout(secondTimeout);
    };
  }, [timelineData]);

  return (
    <>
      <div className="mt-[30px] font-medium mb-6">
        <Link to="/">
          <span className="text-[#707070]">
            {"<"} {t("backToTasks")}
          </span>
        </Link>
      </div>

      <div className="mb-4 ">
        <div className=" mr-0 bg-white rounded-[10px] p-7">
          <div className="flex flex-col">
            <div className="font-medium">{title}</div>
            <TaskDetailTabs setCurrentActiveTab={setActive} activeTab={active} state={"done"} />
          </div>
          <div className="mt-4 ">
            {isLoading || isLoadingCountry || isLoadingTimeline ? (
              <LoadingSpinner size="w-6 h-6" />
            ) : (
              <>
                {active === "description" && (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-col gap-4 my-4 text-[#263238] text-sm">
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-2 text-xs">{t("status")} :</div>
                          <div className="col-span-6 text-[#263238] flex gap-2 ">
                            <div
                              className={`w-4 h-4 rounded-full`}
                              style={{
                                backgroundColor:
                                  data.status === "pending"
                                    ? "#C42F41"
                                    : data.status === "inprogress"
                                    ? "#60C423"
                                    : "#EF905A",
                              }}></div>
                            <div>{data?.status}</div>
                          </div>
                        </div>
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-2 text-xs">{t("dueDate")}:</div>
                          <div className="col-span-6 text-[#263238]">
                            {convertIsoToDateAndHour(data.due_date)}
                          </div>
                        </div>
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-2 text-xs">{t("clientName")}:</div>
                          <div className="col-span-6 text-[#263238]">{data?.company_name}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("domainName")} :</div>
                          <div className="col-span-6">{data?.project_domain}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("targetCountry")} :</div>
                          <div className="col-span-6 ">{countryData?.name}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("socialMediaAccounts")} :</div>
                          <div className="col-span-6">
                            There is no connected a social media account.
                          </div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("keywords")} :</div>
                          <div className="col-span-6">
                            {data?.project_keywords?.map((element: any, index: any) => {
                              return index === data?.project_keywords?.length - 1
                                ? element + " "
                                : element + ", ";
                            })}
                          </div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-2 text-xs">{t("competitors")} :</div>
                          <div className="col-span-6">
                            {data?.project_competitor_domain_names?.map(
                              (element: any, index: any) => {
                                return index === data?.project_competitor_domain_names?.length - 1
                                  ? element + " "
                                  : element + ", ";
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="grid grid-cols-8">
                        <div className="col-span-2 text-xs">{t("clientNotes")} :</div>
                        <div className="col-span-6 mb-3">
                          <textarea
                            id="message"
                            rows={4}
                            disabled
                            defaultValue={timelineData[0]?.content.message}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "></textarea>
                        </div>
                      </div> */}
                      <div className="grid grid-cols-8">
                        <div className="col-span-2  text-xs">{t("clientOldWorks")} :</div>
                        <div className="col-span-6"></div>
                      </div>
                    </div>
                    <div className="flex flex-col border-t border-gray-200 mt-4">
                      <div>
                        <div className="font-semibold my-4">{t("comments")}</div>
                        <div>{data?.sub_content}</div>
                      </div>
                      <div className="font-semibold my-4">{t("workDetail")}</div>
                      <div>Here’s the information about task.</div>
                      <Output
                        renderers={renderers}
                        style={style.header}
                        classNames={classes.header}
                        data={JSON.parse(data?.task_content)}
                      />
                    </div>
                  </>
                )}
                {active === "files" && (
                  <div className="flex flex-col">
                    <div className="flex justify-between flex-col-reverse lg:flex-row ">
                      <div className="h-72 max-h-72 overflow-auto text-base text-[#263238] flex  flex-col-reverse gap-5 pb-5 flex-1 pt-3">
                        {timelineData?.map((item: any, index: any) => {
                          const currentUser = data?.users?.find((e: any) => e.id === item.user_id);
                          const isSystemMessage = systemMessages.includes(
                            item.content.type.toLowerCase()
                          );
                          return (
                            <div
                              key={index}
                              className={`flex gap-2 mr-2  ${
                                isSystemMessage
                                  ? "justify-center italic text-sm"
                                  : currentUser?.id === user?.id
                                  ? "justify-end"
                                  : "justify-start"
                              }`}>
                              <div
                                className={`w-fit ${
                                  item.content.type.toLowerCase() === "review" ? "" : ""
                                }`}>
                                <div
                                  className={`flex text-sm items-center gap-2 justify-end ${
                                    isSystemMessage
                                      ? "hidden"
                                      : currentUser?.id !== user?.id
                                      ? "justify-end flex-row-reverse"
                                      : ""
                                  }  `}>
                                  <div
                                    className={`${
                                      item.content.type.toLowerCase() !== "review" &&
                                      "order-last" &&
                                      item.content.type.toLowerCase() !== "work"
                                    }`}>
                                    {controlSystemMessage(
                                      item.content.type.toLowerCase(),
                                      currentUser?.first_name,
                                      item.user_id === user?.id
                                    )}
                                  </div>
                                  {currentUser?.avatar ? (
                                    <img
                                      className="h-6 w-6 rounded-full bg-gray-400"
                                      src={currentUser?.avatar}
                                      alt={currentUser?.first_name}
                                    />
                                  ) : (
                                    <div className="h-6 w-6 rounded-full bg-gray-300"></div>
                                  )}
                                </div>
                                <div
                                  className={`py-2 px-4 rounded-md mt-2 ${
                                    isSystemMessage
                                      ? "bg-gray-200"
                                      : currentUser?.id === user?.id
                                      ? "bg-[#F3F1F9]"
                                      : "bg-green-100 "
                                  }`}>
                                  {item.content.user_message
                                    ? item.content.user_message
                                    : item.content.message}
                                </div>
                                {item.content.files?.map((file: any, index: any) => {
                                  if (
                                    file.file_type === "image/png" ||
                                    file.file_type === "image/jpeg" ||
                                    file.file_type === "image/jpg"
                                  ) {
                                    return (
                                      <div key={index} className="text-blue-500 flex gap-2 p-1 ">
                                        <Gallery withDownloadButton>
                                          <Item
                                            original={`${file?.file_url || ""}`}
                                            thumbnail={`${file?.file_url || ""}`}
                                            width="1600"
                                            height="1066">
                                            {({ ref, open }: any) => (
                                              <img
                                                width={120}
                                                ref={ref}
                                                onClick={open}
                                                src={`${file?.file_url || ""}`}
                                                alt=""
                                              />
                                            )}
                                          </Item>
                                        </Gallery>
                                      </div>
                                    );
                                  } else if (file.file_type === "application/pdf") {
                                    return (
                                      <div key={index} className="text-blue-500 flex gap-2 p-1">
                                        <a
                                          href={`${file.file_url}`}
                                          target="_blank"
                                          className="flex justify-center items-center">
                                          <img
                                            src="/assets/icons/pdf-repo.svg"
                                            alt="pdf"
                                            width={40}
                                          />
                                          {file.file_name}
                                        </a>
                                      </div>
                                    );
                                  } else
                                    return (
                                      <div key={index} className="text-blue-500 flex gap-2">
                                        <a
                                          href={`${file.file_url}`}
                                          target="_blank"
                                          className="flex justify-center items-center">
                                          <img
                                            src="/assets/icons/file-extension.png"
                                            alt=""
                                            width={40}
                                          />
                                          {file.file_name}
                                        </a>
                                      </div>
                                    );
                                })}
                                <div
                                  className={`flex text-xs mr-2 mt-0.5  ${
                                    isSystemMessage
                                      ? "hidden"
                                      : currentUser?.id !== user?.id
                                      ? "justify-start"
                                      : "justify-end"
                                  } `}>
                                  <div>
                                    {convertUTCDateToLocalDate(
                                      item.create_date.slice(0, -1)
                                    ).toLocaleTimeString(navigator?.language || "tr-TR", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div></div>

                      <div className="border-t w-full lg:border-t-none lg:border-l lg:border-t-0 lg:w-80 p-2 flex flex-col gap-2  pt-3">
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-3 text-xs">{t("status")} :</div>
                          <div className="col-span-5 text-sm flex items-center gap-2 ">
                            <div
                              className={`w-4 h-4 rounded-full`}
                              style={{
                                backgroundColor:
                                  data.status === "pending"
                                    ? "#C42F41"
                                    : data.status === "inprogress"
                                    ? "#60C423"
                                    : "#EF905A",
                              }}></div>
                            <div>{data?.status}</div>
                          </div>
                        </div>
                        <div className="grid grid-cols-8 ">
                          <div className="col-span-3 text-xs">{t("clientName")}:</div>
                          <div className="col-span-5 text-sm">{data?.company_name}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-3 text-xs">{t("domainName")} :</div>
                          <div className="col-span-5 text-sm">{data?.project_domain}</div>
                        </div>
                        <div className="grid grid-cols-8">
                          <div className="col-span-3 text-xs">{t("targetCountry")} :</div>
                          <div className="col-span-5 text-sm">{countryData?.name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* <div
          className="bg-[#F3F1F9] rounded-[10px] p-[15px] shadow mt-8 mb-10 lg:mt-0 lg:mb-0 lg:fixed top-11 right-12 z-10 w-full lg:w-64"
          style={{
            minHeight: "calc(100vh - (80px))",
          }}>
          {isLoading || isLoadingCountry || isLoadingTimeline ? (
            <LoadingSpinner size="w-6 h-6" />
          ) : (
            <div className="flex flex-col gap-4">
              <div className="rounded-[10px] pt-[15px] font-medium">Task Detail</div>
              <div className="text-sm text-[#263238] flex flex-col gap-3">
                <div className="grid grid-cols-5">
                  <div className="col-span-2 text-xs">Due date :</div>
                  <div className="col-span-3 text-sm">{convertIsoToDateAndHour(data.due_date)}</div>
                </div>
                <div className="grid grid-cols-5 ">
                  <div className="col-span-2 text-xs">Client Name:</div>
                  <div className="col-span-3 truncate text-sm">{data?.company_name}'s project</div>
                </div>
                <div className="grid grid-cols-5 ">
                  <div className="col-span-2 text-xs">Status:</div>
                  <div className="col-span-3 capitalize bg-white rounded-lg w-fit py-1 px-2 flex  gap-2 justify-center items-center text-xs">
                    <div
                      className={`w-4 h-4 rounded-full`}
                      style={{
                        backgroundColor:
                          data.status === "pending"
                            ? "#C42F41"
                            : data.status === "inprogress"
                            ? "#60C423"
                            : "#EF905A",
                      }}></div>
                    <div>{data?.status}</div>
                  </div>
                </div>
                {isLoading || isLoadingTimeline ? (
                  <div className="m-4">
                    <LoadingSpinner size="w-5 h-5" />
                  </div>
                ) : (
                  <ProfileTabs data={data} dataPartnerTimeline={timelineData} />
                )}
              </div>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
};

export default TaskDetailPage;
