import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  id: number;
  state: any;
  children: React.ReactNode;
};

const NavigateDetail = (props: Props) => {
  const { id } = props;

  const handleNavigate = () => {
    /*  if (state === "inprogress" || state === "review" || state === "revised") {
      navigate(`/tasks/active/${id}`);
    } else if (state === "finished") {
      navigate(`/tasks/done/${id}`);
    } else {
      navigate(`/tasks/${state}/${id}`);
    } */
  };

  return (
    <tr
      className="bg-white border-b cursor-pointer hover:bg-gray-100 transition-all"
      key={id}
      onClick={() => handleNavigate()}>
      {props.children}
    </tr>
  );
};

export default NavigateDetail;
