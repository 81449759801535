import { registerUser } from "@/api/auth";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";

// Integration
import { RegisterFormDataType } from "@/models/user.interface";
// Hook
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ValidationInput from "@/components/UI/ValidationInput";
import { useTranslation } from "react-i18next";
import { setToken } from "@/utils/token";

const schema = yup
  .object({
    first_name: yup.string().required("Firs name is required"),
    last_name: yup.string().required("Last name is required"),
    phone_number: yup.string().required("Phone number is required"),
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .required()
      .min(6)
      .max(20)
      .matches(/^(?=.*[0-9])/, "Password must contain at least one number"),
  })
  .required();

const Register = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormDataType>({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const { mutate, isLoading: isLoadingPost } = useMutation(registerUser, {
    onSuccess: (data) => {
      setToken(data.data.access_token);

      navigate("/");
    },
    onError: (error: any) => {
      setError(error.response.data.detail);
    },
    retry: false,
  });

  const onSubmit = (data: RegisterFormDataType) => {
    mutate(data);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);
  };

  return (
    <div className="h-screen lg:flex lg:justify-center lg:items-center" data-testid="register-page">
      <div className="absolute top-1 right-1 z-[99]">
        <select
          onChange={handleChange}
          id="countries"
          defaultValue={language}
          className="bg-gray-50 border border-gray-300 active:border-blue-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2">
          <option value="en">{t("english")}</option>
          <option value="tr">{t("turkish")}</option>
        </select>
      </div>
      <div className="min-w-screen max-h-fit flex flex-col  lg:w-[460px] relative">
        <form
          className="flex flex-col gap-5 col-span-1 p-[30px] bg-mainBackground lg:rounded-[50px] h-screen lg:h-fit "
          onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-[42px] font-medium mb-[10px]">{t("signup")}</h1>
          <div className="flex flex-col gap-3">
            <ValidationInput title="First Name" name="firstname">
              <input
                {...register("first_name")}
                placeholder="Enter your first name"
                className="w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
              />
            </ValidationInput>
            <p className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
              {errors.first_name?.message}
            </p>
            <ValidationInput title="Last Name" name="lastname">
              <input
                {...register("last_name")}
                placeholder="Enter your last name"
                className="w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
              />
            </ValidationInput>
            <p className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
              {errors.last_name?.message}
            </p>

            <ValidationInput title={t("phone")} name="phone">
              <div className="flex gap-2">
                <input
                  {...register("phone_number")}
                  placeholder={t("+905050541221")}
                  className="w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
                />
              </div>
            </ValidationInput>
            <p className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
              {errors.phone_number?.message}
            </p>

            <ValidationInput title={t("email")} name="email">
              <input
                {...register("email")}
                type="email"
                placeholder={t("enterYourEmail")}
                className="w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
              />
            </ValidationInput>
            <p className="text-dangerButtonPrimary text-sm -mt-2 ml-2">{errors.email?.message}</p>

            <ValidationInput title={t("password")} name="password">
              <input
                type={"password"}
                {...register("password")}
                placeholder={t("enterYourPassword")}
                className="w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
              />
            </ValidationInput>
            <p className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
              {errors.password?.message}
            </p>
          </div>
          {error && <span className="text-dangerButtonPrimary text-sm -my-2">{error}</span>}
          <div className="mt-[10px] w-full flex justify-center">
            <DefaultButton className="w-full" type="submit" isLoading={isLoadingPost}>
              {t("signup")}
            </DefaultButton>
          </div>
          <span className="text-center text-sm text-textColor mt-3">
            {t("alreadyHaveAnAccount")}{" "}
            <Link to="/login" className="text-sky">
              {t("login")}
            </Link>
            .
          </span>
        </form>
      </div>
    </div>
  );
};

export default Register;
