import { t } from "i18next";

const Payments = () => {
  return (
    <div>
      <>{t("payments")}</>
    </div>
  );
};

export default Payments;
