import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

import { userAtom } from "@/store/atoms/user.atoms";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import ToastNotify from "@/components/UI/ToastNotify";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import Dropdown from "@/components/UI/Dropdown";
import { fetchPartnerRole, fetchPartnerTasks, fetchPartnerWorkExperience } from "@/api/partners";
import { updateAuthMe } from "@/api/auth";
import EditAvatarModal from "@/components/EditAvatarModal";
import WorkExperienceModal from "@/components/WorkExperienceModal";
import { convertIsoToDate } from "@/utils/convertIsoToDate";
import { useTranslation } from "react-i18next";

/* enum PartnerRole {
  Designer = "Designer",
} */

const MyProfile = () => {
  const { t } = useTranslation();
  const imageRef = useRef<any>(null);
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [selectedText, setSelectedText] = useState<string[]>([]);
  const [jobTitle, setJobTitle] = useState("");
  const [bio, setBio] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const [user] = useRecoilState(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const [isExperienceModal, setIsExperienceModal] = useState(false);

  const { mutateAsync, isLoading } = useMutation("updateAuthMe", updateAuthMe, {
    retry: false,
  });

  const handleSelectedText = (text: string) => {
    if (selectedText.find((el: string) => el === text))
      setSelectedText(selectedText.filter((el: string) => el !== text));
    else setSelectedText([...selectedText, text]);
  };

  const handleSubmit = () => {
    if (bio === "" || jobTitle === "" || selectedText.length === 0) {
      setError("Please fill all the fields");
    } else {
      mutateAsync({
        avatar: imageFile,
        description: bio,
        title: jobTitle,
        partner_roles: selectedText,
        status: status,
      })
        .then(() => {
          toast.info("Profile edited successfully");
          //window.location.reload();
        })
        .catch((error: any) => {
          setError(error?.response?.data?.detail || "Something went wrong");
        });
      setError("");
    }
  };

  const { data: roleItems, isLoading: isLoadingRoles } = useQuery("stories", fetchPartnerRole, {
    retry: false,
  });

  useEffect(() => {
    if (user) {
      setImage(user?.avatar || "");
      setJobTitle(user.profile?.title || "");
      setBio(user.profile?.description || "");
      setSelectedText(
        user.profile?.partner_roles &&
          user.profile?.partner_roles?.length > 0 &&
          user.profile?.partner_roles[0] !== ""
          ? user.profile?.partner_roles
          : []
      );
      setStatus(user.profile?.status || "");
    }
  }, [user]);

  const { data: tasks, isLoading: isLoadingTasks } = useQuery<any, Error>(
    ["storis", ""],
    fetchPartnerTasks,
    {
      retry: false,
    }
  );

  const {
    data: workData,
    isLoading: isLoadingWork,
    refetch,
  } = useQuery<
    [
      {
        company_name: string;
        job_title: string;
        start_date: string;
        end_date: string;
        id: number;
      }
    ],
    Error
  >("fetchExperience", fetchPartnerWorkExperience, {
    retry: false,
  });

  return (
    <div className=" flex flex-col md:flex-row gap-4">
      <div
        className="bg-white w-full md:w-72"
        style={{
          minHeight: "calc(100vh - (180px))",
        }}>
        <div className="flex justify-center flex-col items-center mt-[30px]">
          <div className="mb-2">
            <img
              ref={imageRef}
              src={image ? image : "/assets/icons/placeholder.png"}
              alt=""
              className="rounded-full border border-borderStroke h-[100px] w-[100px]"
              width={100}
            />
          </div>

          <div className=" flex flex-col gap-2 justify-center items-center mx-4 ">
            <div className="font-semibold">
              {user?.first_name} {user?.last_name}
            </div>
            <div className="text-sm">{user?.email}</div>
            <div className="text-sm capitalize">{jobTitle}</div>
          </div>

          <div className="mt-7 flex justify-center gap-1 w-full">
            <div></div>
            <div className="text-sm font-medium">{t("workExperience")}</div>
            <button
              onClick={() => {
                setIsExperienceModal(true);
              }}>
              <img src="/assets/icons/edit.svg" alt="" width={20} />
            </button>
          </div>
          <div className="w-full">
            {isLoadingWork ? (
              <div className="mt-2">
                <LoadingSpinner size="w-5 h-5" />
              </div>
            ) : (
              workData?.map((work) => {
                return (
                  <div className="bg-white p-[15px] rounded-[10px] mx-[15px] my-[15px]">
                    <div className="text-xs font-medium mb-2">{work.company_name}</div>
                    <div className="text-xs ">
                      <div>{work.job_title}</div>
                      <div>
                        {work.end_date ? convertIsoToDate(work.end_date) : "Present"} -{" "}
                        {convertIsoToDate(work.start_date)}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      <div className=" bg-white rounded-[10px] shadow flex justify-between flex-col xl:flex-row flex-1">
        <div className="flex-1 border-r ">
          <div className="w-full border-b ">
            <div className="p-[30px] pb-[15px] font-medium">{t("personalDetails")}</div>
          </div>

          <div className="lg:pr-12">
            <div className="p-[30px] flex flex-col gap-2">
              <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium leading-6 text-[#455a64]">
                  Photo
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex items-center gap-x-3">
                    {/* <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" /> */}
                    <img
                      ref={imageRef}
                      src={image ? image : "/assets/icons/placeholder.png"}
                      alt=""
                      className="rounded-full border border-borderStroke h-12 w-12"
                      width={100}
                    />
                    <button
                      type="button"
                      onClick={() => setIsOpen(!isOpen)}
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Change
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div className="w-full sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-3 items-center">
                  <div className="text-sm mb-2 text-[#455a64]">{t("role")}</div>
                  {isLoadingRoles ? (
                    <LoadingSpinner size="w-5 h-5" />
                  ) : (
                    <div className="col-span-2">
                      <Dropdown
                        items={roleItems}
                        onSelect={handleSelectedText}
                        selected={selectedText}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div className="w-full  sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-3 items-center">
                    <div className="text-sm mb-2 text-[#455a64]">{t("jobTitle")}</div>
                    <input
                      placeholder={t("enterYourJobTitle")}
                      className="col-span-2 w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="w-full sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-3 items-center">
                  <div className="text-sm mb-2 text-[#455a64]">{t("Bio")}</div>

                  <textarea
                    rows={4}
                    placeholder={t("enterYourBio")}
                    className="col-span-2 w-full appearance-none py-[10px] px-5 text-black text-base leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-3 items-center">
                <div className="text-textColor text-sm">{t("status")}</div>

                <label
                  htmlFor="default-toggle"
                  className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    id="default-toggle"
                    className="sr-only peer"
                    checked={status === "active"}
                    onChange={() => setStatus(status === "active" ? "passive" : "active")}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 capitalize">
                    {status}
                  </span>
                </label>
              </div>
              <p className="text-dangerButtonPrimary text-sm -mt-2 ml-2">{error}</p>
              <div className="flex justify-end mt-4">
                {isLoading ? (
                  <LoadingSpinner size="w-5 h-5" />
                ) : (
                  <DefaultButton className="w-fit" onClick={() => handleSubmit()}>
                    {t("save")}
                  </DefaultButton>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  <div className="mt-5 lg:mt-0 flex-3 h-full ">
          <div className="w-full border-b ">
            <div className="p-[30px] pb-[15px] px-[50px] font-medium">{t("accountDetails")}</div>
          </div>
          <div className="p-12 flex flex-col gap-12">
            {isLoadingTasks ? (
              <div className="flex justify-center items-center h-full flex-1">
                <LoadingSpinner size="w-6 h-6" />
              </div>
            ) : (
              <>
                <div className="p-3 border border-[#C4C4C4] rounded-[10px] text-center flex flex-col gap-2">
                  <div className="text-xs text-[#263238]">{t("finishedProjects")}</div>
                  <div className="font-medium text-[22px]">
                    {tasks.filter((task: any) => task.status === "finished").length}
                  </div>
                </div>
                <div className="p-3 border border-[#C4C4C4] rounded-[10px] text-center flex flex-col gap-2">
                  <div className="text-xs text-[#263238]">{t("pendingProjects")}</div>
                  <div className="font-medium text-[22px]">
                    {
                      tasks.filter(
                        (task: any) =>
                          task.status === "inprogress" ||
                          task.status === "review" ||
                          task.status === "revised"
                      ).length
                    }
                  </div>
                </div>
              </>
            )}
          </div>
        </div> */}

        {!isLoadingWork && (
          <WorkExperienceModal
            modalState={isExperienceModal}
            setModalState={() => setIsExperienceModal(false)}
            workData={workData}
            refetch={refetch}
          />
        )}

        <EditAvatarModal
          modalState={isOpen}
          setModalState={() => setIsOpen(false)}
          setFile={setImageFile}
          setImage={setImage}>
          <div></div>
        </EditAvatarModal>
        <ToastNotify />
      </div>
    </div>
  );
};

export default MyProfile;
