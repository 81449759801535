/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

interface Props {
  items: string[];
  onSelect: (item: any) => void;
  selected: string[];
}

export default function Dropdown(props: Props) {
  const { items, selected, onSelect } = props;

  function isSelected(value: string) {
    return selected.find((el) => el === value) ? true : false;
  }

  return (
    <Listbox
      as="div"
      className="relative inline-block text-left w-full"
      value={selected}
      onChange={onSelect}>
      <div>
        <Listbox.Button className="capitalize inline-flex justify-between w-full rounded-[10px] border border-gray-300 shadow-sm px-4 py-2.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-gray-100 focus:ring-secondBackground">
          <span className="block truncate">
            {selected.length > 0 ?  `Selected role (${selected.length})` : "Select role"}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor">
              <path
                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Listbox.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Listbox.Options className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item: any, index) => {
              const selected = isSelected(item.name);
              return (
                <Listbox.Option key={index} value={item.name}>
                  {({ active }) => (
                    <div
                      className={`${
                        active ? "text-white bg-blue-600" : "text-gray-900"
                      } cursor-default select-none relative py-2 pl-8 pr-4`}>
                      <span
                        className={`${selected ? "font-semibold" : "font-normal"} block truncate`}>
                        {item.title}
                      </span>
                      {selected && (
                        <span
                          className={`${
                            active ? "text-white" : "text-blue-600"
                          } absolute inset-y-0 left-0 flex items-center pl-1.5`}>
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              );
            })}
          </div>
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
