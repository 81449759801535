import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import WhiteButton from "@/components/buttons/WhiteButton";
import { createStrategy } from "@/store/atoms/createStrategy.atoms";
import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

const steps = [
  { name: "Step 1", href: "#", status: "current" },
  { name: "Step 2", href: "#", status: "upcoming" },
  { name: "Step 3", href: "#", status: "upcoming" },
  { name: "Step 4", href: "#", status: "upcoming" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  setStep: (step: number) => void;
};

const SelectLanguage = (props: Props) => {
  const { setStep } = props;
  const [newStrategy, setNewStrategy] = useRecoilState(createStrategy);

  const handleNextStep = () => {
    if (!newStrategy.roadmap.language_code) return toast.error("Please select language");
    setStep(2);
  };

  return (
    <div>
      <div className="pb-7">
        <div className="font-medium text-lg">Select Language</div>
        <div className="text-gray-500 text-sm">In which language will you create the strategy?</div>
      </div>

      <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            Language
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0 ">
            <select
              onChange={(e) =>
                setNewStrategy({
                  roadmap: {
                    ...newStrategy.roadmap,
                    language_code: e.target.value || "en",
                  },
                  roadmap_stories: {
                    ...newStrategy.roadmap_stories,
                  },
                })
              }
              defaultValue={newStrategy.roadmap.language_code}
              className="border border-gray-300 rounded-md px-2 py-2 text-gray-500 w-full">
              <option hidden selected>
                Select Language
              </option>
              <option value="en">English</option>
              <option value="tr">Turkish</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-center py-5">
        <nav>
          <ol className="flex items-center">
            {steps.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                  "relative"
                )}>
                {step.status === "complete" ? (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-indigo-600" />
                    </div>
                    <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 ">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                ) : step.status === "current" ? (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <div
                      className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                      aria-current="step">
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>

      <div className="flex gap-4 justify-end mt-4">
        <DefaultButton className="w-fit" onClick={handleNextStep}>
          Next
        </DefaultButton>
      </div>
    </div>
  );
};

export default SelectLanguage;
