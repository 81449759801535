// Package
import axios from "axios";
// Util
import { getToken } from "@/utils/token";

/* const getBrowserDefaultLanguage = () => {
  let currentLanguage = sessionStorage.getItem("language") || navigator?.languages?.[0];
  currentLanguage = currentLanguage.split("-")[0];
  if (currentLanguage === "tr" || currentLanguage === "en") return currentLanguage;
  else return "en";
}; */

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Accept-Language": (localStorage.getItem("language") as string) || "en",
  },
});

api.interceptors.request.use((request: any) => {
  request.headers.common.Authorization = `Bearer ${getToken()}`;

  return request;
});

api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("partnerAuthToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
