import { atom } from "recoil";

interface ICreateStrategy {
  roadmap: {
    name: string;
    language_code: string;
    category_id?: number;
    description: string;
    rules?: [];
    duration: number;
    // prerequisites: null;
    one_time_only: boolean;
    application_status: string;
    created_by: number;
  };
  roadmap_stories: any;
}

export const createStrategy = atom<ICreateStrategy>({
  key: "createStrategy",
  default: {
    roadmap: {
      name: "",
      language_code: "",
      category_id: 0,
      description: "",
      rules: [],
      duration: 0,
      // prerequisites: null,
      one_time_only: false,
      application_status: "draft",
      created_by: 0,
    },
    roadmap_stories: [
      {
        story_id: 0,
        order: 0,
        is_skippable: false,
      },
    ],
  },
});

export const selectedTasks = atom<any[]>({
  key: "selectedTasks",
  default: [],
});
