import { t } from "i18next";
import { useQuery } from "react-query";
import { fetchCountryById } from "@/api/country";

type Props = {
  data: any;
};

const ClientInformation = (props: Props) => {
  const { data } = props;

  const { data: countryData, isLoading: isLoadingCountry } = useQuery(
    "fetchCountryById",
    () => fetchCountryById(data?.project_countries),
    {
      retry: false,
      enabled: !!data?.project_countries,
    }
  );

  return (
    <div>
      <div className={`flex flex-col flex-1`}>
        <div className="flex flex-col gap-4 my-4 text-[#263238] text-sm">
          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("companyName/ClientName")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.company_name}
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("domainName")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_domain}
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("targetCountry")}
            </label>
            {isLoadingCountry ? (
              <div className="animate-pulse">Loading...</div>
            ) : (
              <div className="mt-1">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                  <input
                    type="text"
                    name="website"
                    id="website"
                    className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                    disabled
                    defaultValue={countryData?.name}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("keywords")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_keywords?.join(", ")}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("competitors")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_competitor_domain_names?.join(", ")}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("businessName")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_bussiness_data?.businessName}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("businessDescription")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_bussiness_data?.businessDescription}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("businessProvider")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_bussiness_data?.businessProvider}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("businessType")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_bussiness_data?.businessType}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
              {t("onlineSales")}
            </label>
            <div className="mt-1">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset outline-none">
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                  disabled
                  defaultValue={data?.project_bussiness_data?.onlineSales}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientInformation;
