import InputLoader from "@/components/InputLoader";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { convertUTCDateToLocalDate } from "@/utils/convertUTCDateToLocalDate";
import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

type Props = {
  data: any;
  timelineData: any;
  user: any;
  id: number;
  refetch: any;
  setIsPopup: any;
};

const systemMessages = ["system", "notification", "auto_message"];

const controlSystemMessage = (
  text: string,
  clientName: string,
  isYourMessage = false,
  userRole = ""
) => {
  if (systemMessages.includes(text)) {
    return <span className="capitalize">System</span>;
  }

  if (isYourMessage) {
    return <span>You</span>;
  } else {
    return (
      <span>
        {clientName}
        {userRole !== "partner_user" ? " (Customer)" : " (Partner)"}
      </span>
    );
  }
};

const Comments = (props: Props) => {
  const { data, timelineData, user, id, refetch, setIsPopup } = props;

  return (
    <div>
      <div className="flex justify-between flex-row ">
        <div className="h-[calc(100vh-320px)] flex-1 overflow-auto text-base text-[#263238] flex flex-col-reverse gap-5 pb-5 pt-3">
          {timelineData?.map((item: any, index: any) => {
            const currentUser = data?.users?.find((e: any) => e.id === item.user_id);
            const isSystemMessage = systemMessages.includes(item.content.type.toLowerCase());
            return (
              <div
                key={index}
                className={`flex gap-2 mr-2  ${
                  isSystemMessage
                    ? "justify-center text-sm"
                    : currentUser?.id === user?.id
                    ? "justify-end"
                    : "justify-start"
                }`}>
                <div
                  className={`p-3 ${
                    isSystemMessage
                      ? "bg-gray-200 w-fit rounded"
                      : currentUser?.id === user?.id
                      ? "bg-[#F3F1F9] w-full"
                      : "bg-green-100 w-full "
                  } ${item.content.type.toLowerCase() === "review" ? "" : ""}`}>
                  <div
                    className={`flex text-sm items-center gap-2 justify-end  flex-row-reverse ${
                      isSystemMessage ? "hidden" : ""
                    }  `}>
                    <div
                      className="my-2"
                      /* className={`${
                        item.content.type.toLowerCase() !== "review" &&
                        "order-last" &&
                        item.content.type.toLowerCase() !== "work"
                      }
                      `} */
                    >
                      {controlSystemMessage(
                        item.content.type.toLowerCase(),
                        currentUser?.first_name,
                        item.user_id === user?.id,
                        currentUser?.role
                      )}
                    </div>
                    {currentUser?.avatar ? (
                      <img
                        className="h-6 w-6 rounded-full bg-gray-400"
                        src={currentUser?.avatar}
                        alt={currentUser?.first_name}
                      />
                    ) : (
                      <div className="h-6 w-6 rounded-full bg-gray-300"></div>
                    )}
                  </div>
                  <div
                    className={`flex text-xs mr-2 mt-0.5 mb-2 ${isSystemMessage ? "hidden" : ""} `}>
                    <div>
                      {convertUTCDateToLocalDate(item.create_date.slice(0, -1)).toLocaleTimeString(
                        navigator?.language || "tr-TR",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </div>
                  </div>
                  <div className={`rounded-md `}>
                    {item.content.user_message ? item.content.user_message : item.content.message}
                  </div>
                  {item.content.files?.map((file: any, index: any) => {
                    if (
                      file.file_type === "image/png" ||
                      file.file_type === "image/jpeg" ||
                      file.file_type === "image/jpg"
                    ) {
                      return (
                        <div key={index} className="text-blue-500 flex gap-2 p-1 ">
                          <Gallery withDownloadButton>
                            <Item
                              original={`${file?.file_url || ""}`}
                              thumbnail={`${file?.file_url || ""}`}
                              width="1600"
                              height="1066">
                              {({ ref, open }: any) => (
                                <img
                                  width={120}
                                  ref={ref}
                                  onClick={open}
                                  src={`${file?.file_url || ""}`}
                                  alt=""
                                />
                              )}
                            </Item>
                          </Gallery>
                        </div>
                      );
                    } else if (file.file_type === "application/pdf") {
                      return (
                        <div key={index} className="text-blue-500 flex gap-2 p-1">
                          <a
                            href={`${file.file_url}`}
                            target="_blank"
                            className="flex justify-center items-center">
                            <img src="/assets/icons/pdf-repo.svg" alt="pdf" width={40} />
                            {file.file_name}
                          </a>
                        </div>
                      );
                    } else
                      return (
                        <div key={index} className="text-blue-500 flex gap-2">
                          <a
                            href={`${file.file_url}`}
                            target="_blank"
                            className="flex justify-center items-center">
                            <img src="/assets/icons/file-extension.png" alt="" width={40} />
                            {file.file_name}
                          </a>
                        </div>
                      );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="border-t">
        <div>
          <InputLoader
            type="message"
            status={data?.status}
            partnerTaskId={id}
            timelineRefetch={refetch}
          />
        </div>
      </div>
      <DefaultButton
        onClick={() => setIsPopup(true)}
        className="w-fit flex items-center fixed bottom-5 right-12">
        <span>Send Work</span>
      </DefaultButton>
    </div>
  );
};

export default Comments;
