import React from "react";

type Props = {
  setActive: (active: "description" | "taskDetails" | "files") => void;
  activeState: "description" | "taskDetails" | "files";
  active: boolean;
  color?: string;
  title: string;
};

const TabButton = (props: Props) => {
  const { activeState, setActive, color, title, active } = props;

  return (
    <button
      className={`cursor-pointer py-4 px-5  rounded-t-lg  border-transparent ${
        active ? "bg-blue-50 border-b-2 border-blue-500 text-black" : "text-gray-500"
      }  transition-all font-medium text-sm `}
      onClick={() => setActive(activeState)}>
      <span>{title}</span>
    </button>
  );
};

export default TabButton;
