import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchPartnerTasks } from "@/api/partners";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

import { CalendarIcon, UsersIcon } from "@heroicons/react/20/solid";
import AllTaskModal from "@/features/tasks/components/AllTaskModal";
import TaskDetailModal from "@/features/tasks/components/TaskDetailModal";
import { t } from "i18next";

const AllTasksPage = () => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      localStorage.removeItem("taskStatus");
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => clearTimeout(timeout);
  }, []);

  const { data: tasks, isLoading } = useQuery<any, Error>(["stories", ""], fetchPartnerTasks, {
    retry: false,
    refetchInterval: 5000,
    cacheTime: 0,
  });

  const handleNavigate = (id: number) => {
    setSelectedTaskId(id);
    setDetailModal(true);
  };

  return (
    <>
      <div className="font-medium mb-6">
        <input
          type="search"
          placeholder={t("search")}
          className="border border-gray-300 rounded-md px-4 py-1 w-3/4 focus:outline-none"
        />
      </div>

      <div>
        {isLoading ? (
          <LoadingSpinner size="w-6 h-6" />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="mt-4">
                <div className="mb-8 flex justify-between items-center ">
                  <div className="font-bold text-2xl ">
                    {t("To Do")}{" "}
                    <span className="text-lg font-normal">
                      ({tasks?.filter((task: any) => task.status === "inprogress").length})
                    </span>
                  </div>
                  <div
                    className=" cursor-pointer text-[#4F46E5] font-semibold"
                    onClick={() => {
                      setStatus("inprogress");
                      setOpen(true);
                    }}>
                    {t("View All")}
                  </div>
                </div>
                <ul className="divide-y divide-gray-200 flex flex-col gap-5">
                  {tasks
                    ?.filter((task: any) => task.status === "inprogress")
                    .slice(0, 5)
                    .map((t: any) => (
                      <li key={t.id}>
                        <div
                          onClick={() => handleNavigate(t.id)}
                          className="block bg-white cursor-pointer">
                          <div className="px-4 py-4 ">
                            <div className="flex items-center justify-between">
                              <p className="truncate text-sm font-medium text-indigo-600">
                                {t.task_name}
                              </p>
                              <div className="ml-2 flex flex-shrink-0">
                                <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  {t.type}
                                </p>
                              </div>
                            </div>
                            <div className="mt-2 ">
                              <div className="">
                                <p className="flex items-center text-sm text-gray-500">
                                  <UsersIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {t.company_name}
                                </p>
                              </div>
                              <div className="mt-2 flex items-center text-sm text-gray-500 ">
                                <CalendarIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>Due Date {new Date(t.due_date).toLocaleDateString()}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="mt-4">
                <div className="mb-8 flex justify-between items-center ">
                  <div className="font-bold text-2xl ">
                    {t("In Review")}{" "}
                    <span className="text-lg font-normal">
                      (
                      {
                        tasks?.filter(
                          (task: any) => task.status === "review" || task.status === "revised"
                        ).length
                      }
                      )
                    </span>
                  </div>
                  <div
                    className="text-[#4F46E5] font-semibold cursor-pointer"
                    onClick={() => {
                      setStatus("review");
                      setOpen(true);
                    }}>
                    {t("View All")}
                  </div>
                </div>
                <ul className="divide-y divide-gray-200 flex flex-col gap-5">
                  {tasks
                    ?.filter((task: any) => task.status === "review" || task.status === "revised")
                    .slice(0, 5)
                    .map((t: any) => (
                      <li key={t.id}>
                        <div
                          onClick={() => handleNavigate(t.id)}
                          className="block bg-white cursor-pointer">
                          <div className="px-4 py-4 ">
                            <div className="flex items-center justify-between">
                              <p className="truncate text-sm font-medium text-indigo-600">
                                {t.task_name}
                              </p>
                              <div className="ml-2 flex flex-shrink-0">
                                <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  {t.type}
                                </p>
                              </div>
                            </div>
                            <div className="mt-2 ">
                              <div className="">
                                <p className="flex items-center text-sm text-gray-500">
                                  <UsersIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {t.company_name}
                                </p>
                              </div>
                              <div className="mt-2 flex items-center text-sm text-gray-500 ">
                                <CalendarIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>Due Date {new Date(t.due_date).toLocaleDateString()}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="mt-4">
                <div className="mb-8 flex justify-between items-center ">
                  <div className="font-bold text-2xl ">
                    {t("done")}{" "}
                    <span className="text-lg font-normal">
                      ({tasks?.filter((task: any) => task.status === "finished").length})
                    </span>
                  </div>
                  <div
                    className="text-[#4F46E5] font-semibold cursor-pointer"
                    onClick={() => {
                      setStatus("finished");
                      setOpen(true);
                    }}>
                    {t("View All")}
                  </div>
                </div>
                <div className="divide-y divide-gray-200 flex flex-col gap-5">
                  {tasks
                    ?.filter((task: any) => task.status === "finished")
                    .slice(0, 5)
                    .map((t: any) => {
                      return (
                        <div key={t.id}>
                          <div
                            onClick={() => handleNavigate(t.id)}
                            className="block bg-white cursor-pointer">
                            <div className="px-4 py-4 ">
                              <div className="flex items-center justify-between">
                                <p className="truncate text-sm font-medium text-indigo-600">
                                  {t.task_name}
                                </p>
                                <div className="ml-2 flex flex-shrink-0">
                                  <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    {t.type}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-2 ">
                                <div className="">
                                  <p className="flex items-center text-sm text-gray-500">
                                    <UsersIcon
                                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    {t.company_name}
                                  </p>
                                </div>
                                <div className="mt-2 flex items-center text-sm text-gray-500 ">
                                  <CalendarIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />

                                  <div>Due Date {new Date(t.due_date).toLocaleDateString()}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {detailModal && (
        <TaskDetailModal open={detailModal} setOpen={setDetailModal} id={selectedTaskId} />
      )}
      {open && <AllTaskModal status={status} open={open} setOpen={setOpen} />}
    </>
  );
};

export default AllTasksPage;
