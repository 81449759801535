import { fetchRoadmapCategories } from "@/api/roadmap";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import WhiteButton from "@/components/buttons/WhiteButton";
import { createStrategy } from "@/store/atoms/createStrategy.atoms";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import { t } from "i18next";

type Props = {
  setStep: (step: number) => void;
};

const steps = [
  { name: "Step 1", href: "#", status: "complete" },
  { name: "Step 2", href: "#", status: "current" },
  { name: "Step 3", href: "#", status: "upcoming" },
  { name: "Step 4", href: "#", status: "upcoming" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const NewStrategy = (props: Props) => {
  const { setStep } = props;
  const [newStrategy, setNewStrategy] = useRecoilState(createStrategy);
  const { data, isLoading: isLoadingCategories } = useQuery(
    "fetchRoadmapCategories",
    fetchRoadmapCategories
  );

  const handleNextStep = () => {
    if (
      !newStrategy.roadmap.name ||
      !newStrategy.roadmap.category_id ||
      !newStrategy.roadmap.description
    )
      return toast.error("Please fill in all fields");
    setStep(3);
  };

  return (
    <div>
      <div className="pb-7">
        <div className="font-medium text-lg">{t("New strategy")}</div>
        <div className="text-gray-500 text-sm">
          {t("Get started by filling in the information below to create your new strategy.")}
        </div>
      </div>

      <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            {t("Strategy name")}
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0 ">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              defaultValue={newStrategy.roadmap.name}
              onChange={(e) =>
                setNewStrategy({
                  roadmap: { ...newStrategy.roadmap, name: e.target.value },
                  roadmap_stories: newStrategy.roadmap_stories,
                })
              }
              className="outline-none block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            {t("Strategy Label")}
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0 ">
            {isLoadingCategories ? (
              <LoadingSpinner size="w-6 h-6" />
            ) : (
              <select
                onChange={(e) =>
                  setNewStrategy({
                    roadmap: { ...newStrategy.roadmap, category_id: +e.target.value },
                    roadmap_stories: newStrategy.roadmap_stories,
                  })
                }
                defaultValue={newStrategy.roadmap.category_id}
                className="border border-gray-300 rounded-md px-2 py-2 text-gray-500 w-full focus:ring-blue-500 focus:border-blue-500 outline-none">
                <option selected hidden>
                  {t("Select a label")}
                </option>

                {data?.map((item: any) => (
                  <option key={item.name} value={item.id}>
                    {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            {t("Prerequisite")}
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0 ">
            <select
              disabled
              className="border border-gray-300 rounded-md px-2 py-2 text-gray-500 w-full">
              <option value="en">{t("default")}</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
            {t("description")}
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0 ">
            <textarea
              onChange={(e) =>
                setNewStrategy({
                  roadmap: { ...newStrategy.roadmap, description: e.target.value },
                  roadmap_stories: { ...newStrategy.roadmap_stories },
                })
              }
              defaultValue={newStrategy.roadmap.description}
              rows={4}
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="outline-none block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center py-5">
        <nav>
          <ol className="flex items-center">
            {steps.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                  "relative"
                )}>
                {step.status === "complete" ? (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-indigo-600" />
                    </div>
                    <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 ">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                ) : step.status === "current" ? (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <div
                      className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                      aria-current="step">
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>

      <div className="flex gap-4 justify-end mt-4">
        <WhiteButton
          onClick={() => {
            setStep(1);
          }}
          className="w-fit">
          {t("back")}
        </WhiteButton>
        <DefaultButton className="w-fit" onClick={handleNextStep}>
          {t("next")}
        </DefaultButton>
      </div>
    </div>
  );
};

export default NewStrategy;
