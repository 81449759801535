import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

import TaskDetailTabs from "@/components/tabs/taskDetailTabs/index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { fetchPartnerTaskDetail, fetchPartnerTaskTimeline } from "@/api/partners";

import { userAtom } from "@/store/atoms/user.atoms";

import Output from "editorjs-react-renderer";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchCountryById } from "@/api/country";

import InputLoader from "@/components/InputLoader";
import { convertIsoToDateAndHour } from "@/utils/convertIsoToDate";
import { useTranslation } from "react-i18next";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import PopupModal from "@/components/PopupModal/PopupModal";

import { convertUTCDateToLocalDate } from "@/utils/convertUTCDateToLocalDate";
import ClientInformation from "./ClientInformation";
import Comments from "./Comments";
import Details from "./Details";

const sourceUrl = process.env.REACT_APP_CONTENT_URL;

const systemMessages = ["system", "notification", "auto_message"];

const CustomParagraphRenderer = ({ data }: any) => {
  const alt = data.file.alt ? data.file.alt : "";
  const src = data.file.url ? sourceUrl + data.file.url : "";
  const width = data.file.width ? data.file.width : "auto";
  const height = data.file.height ? data.file.height : "auto";

  return (
    <div className="my-2">
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  );
};

const CustomListRenderer = ({ data }: any) => {
  return (
    <div className="m-5">
      <ul>
        {data.items.map((item: any, index: any) => (
          <li className="list-disc" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const style = {
  header: {
    textAlign: "left",
    margin: "10px 20px",
  },
  paragraph: {
    fontSize: "16px",
  },
};

const classes = {
  header: "header-class1 header-class2",
  paragraph: "paragraph-class",
};

const controlSystemMessage = (
  text: string,
  clientName: string,
  isYourMessage = false,
  userRole = ""
) => {
  if (systemMessages.includes(text)) {
    return <span className="capitalize">System</span>;
  }

  if (isYourMessage) {
    return <span>You</span>;
  } else {
    return (
      <span>
        {clientName}
        {userRole !== "partner_user" ? " (Customer)" : " (Partner)"}
      </span>
    );
  }
};

interface Props {
  id: number;
}

const TaskContent = (props: Props) => {
  const { id } = props;
  const { t } = useTranslation();

  const renderers = {
    image: CustomParagraphRenderer,
    list: CustomListRenderer,
  };

  const [user] = useRecoilState(userAtom);
  const [title, setTitle] = useState("");
  const [active, setActive] = useState<"description" | "taskDetails" | "files">("files");
  const [isPopup, setIsPopup] = useState(false);

  const { data, isLoading, isSuccess } = useQuery(
    "fetchPartnerTaskDetail",
    () => fetchPartnerTaskDetail(id),
    {
      retry: false,
      cacheTime: 0,
      refetchInterval: 5000,
    }
  );

  const {
    data: timelineData,
    isLoading: isLoadingTimeline,
    refetch,
  } = useQuery("fetchPartnerTaskTimeline", () => fetchPartnerTaskTimeline(data?.project_story_id), {
    cacheTime: 0,
    retry: false,
    refetchInterval: 5000,
    enabled: !!data?.project_story_id,
  });

  const { data: countryData, isLoading: isLoadingCountry } = useQuery(
    "fetchCountryById",
    () => fetchCountryById(data?.project_countries),
    {
      retry: false,
      enabled: !!data?.project_countries,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const name = data.sub_title ? " - " + data.sub_title : "";
      setTitle(data.task_name + name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      {isLoading || isLoadingCountry || isLoadingTimeline ? (
        <LoadingSpinner size="w-6 h-6" />
      ) : (
        <div className="mb-4 h-full ">
          <div className=" mr-0  bg-white rounded-[10px] p-7 relative h-full ">
            <div className="flex flex-col flex-1">
              <div className="flex justify-end absolute right-4 top-4">
                <PopupModal isPopup={isPopup} closeAction={() => setIsPopup(false)}>
                  <div>
                    <div className="font-medium text-lg mb-4">Send Work</div>
                    <div className="text-[#707070] mb-4">
                      Please send your work to the client by clicking the button below.
                    </div>
                  </div>
                  <div>
                    <InputLoader
                      type="work"
                      status={data?.status}
                      partnerTaskId={id}
                      timelineRefetch={refetch}
                    />
                  </div>
                </PopupModal>
              </div>
              <div className="font-medium">{title}</div>
              <TaskDetailTabs setCurrentActiveTab={setActive} activeTab={active} state="active" />
            </div>
            <div className="">
              <>
                {active === "description" && <ClientInformation data={data} />}
                {active === "taskDetails" && <Details data={data} />}
                {active === "files" && (
                  <Comments
                    data={data}
                    id={id}
                    refetch={refetch}
                    setIsPopup={setIsPopup}
                    timelineData={timelineData}
                    user={user}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskContent;
