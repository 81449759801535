const getToken = () => {
  return localStorage.getItem("partnerAuthToken");
};

const setToken = (token: string) => {
  localStorage.setItem("partnerAuthToken", token);
};

const removeToken = () => {
  localStorage.removeItem("partnerAuthToken");
};

export { getToken, setToken, removeToken };
