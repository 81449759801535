import { useMutation } from "react-query";
import { postPartnerTaskTimeline } from "@/api/partners";
import { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";

import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { useTranslation } from "react-i18next";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

interface Props {
  partnerTaskId: number;
  timelineRefetch: () => void;
  status: string;
  type: "message" | "work";
}

const InputLoader = (props: Props) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>();
  const [comment, setComment] = useState("");
  const { partnerTaskId, status, type } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { mutateAsync, isLoading: isLoadingPostTimeline } = useMutation(
    "postPartnerTaskTimeline",
    postPartnerTaskTimeline,
    {
      retry: false,
    }
  );

  const handleSendCommment = async () => {
    if (!comment) {
      if (!files) {
        toast.error("Please fill comment or upload file");
        return;
      } else {
        setComment("");
        mutateAsync({
          partner_task_id: partnerTaskId,
          message: comment,
          files: files,
          is_work: type === "work" ? true : false,
        })
          .then(() => {
            setComment("");
            setFiles([]);
            toast.info("Comment sent successfully");
            props.timelineRefetch();
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error sending comment");
          });
      }
    } else {
      setComment("");
      mutateAsync({
        partner_task_id: partnerTaskId,
        message: comment,
        files: files,
        is_work: type === "work" ? true : false,
      })
        .then(() => {
          setComment("");
          setFiles([]);
          toast.info("Comment sent successfully");
          props.timelineRefetch();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error sending comment");
        });
    }
  };

  // on enter key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();
        handleSendCommment();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, files]);

  return (
    <div>
      <div>
        <div className="flex items-center relative">
          <textarea
            ref={textAreaRef}
            rows={1}
            id="website-admin"
            className="rounded-none resize-none outline-none border  text-gray-900  block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
            placeholder={t("enterComment")}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <span className="absolute right-20">
            <ArrowDownTrayIcon
              onClick={() => inputRef.current?.click()}
              className="h-5 text-black"
            />
            <input
              onChange={(e) => {
                if (e.target.files) {
                  setFiles(e.target.files);
                }
              }}
              ref={inputRef}
              type="file"
              className="hidden"
            />
          </span>

          <DefaultButton
            isDisabled={status === "finished"}
            onClick={() => handleSendCommment()}
            isLoading={isLoadingPostTimeline}
            className={`rounded-none w-fit ${
              status === "finished" && "bg-gray-400 hover:bg-gray-400"
            }`}>
            <PaperAirplaneIcon className="h-5 text-white" />
          </DefaultButton>
        </div>
        {/*  <FilePond
          className="cursor-pointer"
          files={files}
          allowReorder={true}
          allowMultiple={true}
          onupdatefiles={(fileItems) => {
            setFiles(fileItems.map((fileItem) => fileItem.file));
          }}
          labelIdle={`<div className="cursor-pointer "> 
          <svg aria-hidden="true" class="cursor-pointer  h-7 text-gray-400 flex justify-center w-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
          ${t("dragAndDrop")} <span class="filepond--label-action">${t("browse")}</span>
          </div>`}
          iconDone="done"
        /> */}
      </div>
      {/*write files as badge */}
      {files && (
        <div className="flex items-center mt-2">
          {Array.from(files).map((file: any, index: number) => (
            <div
              key={index}
              className="flex items-center justify-center bg-indigo-100 rounded-full h-8 w-20 mr-2">
              <span className="text-xs font-medium leading-none text-gray-800 truncate">
                {file.name}
              </span>
              <span>
                <svg
                  onClick={() => {
                    const newFiles = Array.from(files);
                    newFiles.splice(index, 1);
                    setFiles(newFiles);
                  }}
                  className="h-4 w-4 text-gray-500 ml-1 cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InputLoader;
