import Input from "@/components/UI/Input";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { postPartnerWorkExperience } from "@/api/partners";
import { toast } from "react-toastify";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

type Props = {
  setShowNewCompany: (state: boolean) => void;
  refetch: () => void;
};

const AddNewCompany = (props: Props) => {
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  /*   const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); */

  const { mutateAsync, isLoading } = useMutation("postExperience", postPartnerWorkExperience);

  const { setShowNewCompany } = props;

  const handleSave = () => {
    const startDateISO = new Date(startDate).toISOString();
    const endDateISO = endDate ? new Date(endDate).toISOString() : null;

    mutateAsync({
      company_name: companyName,
      job_title: role,
      start_date: startDateISO,
      end_date: endDateISO,
    })
      .then(() => {
        setShowNewCompany(false);
        props.refetch();
        toast.success("Experience added successfully");
      })
      .catch(() => {
        toast.error("Experience could not be saved");
      });
  };

  return (
    <div className="text-sm flex flex-col gap-4 px-7 pb-5">
      <div className="font-medium text-base">Work Experience</div>
      <div className="flex flex-col gap-4">
        <Input
          placeholder="Company Name"
          name="companyName"
          title="Company Name"
          onChange={(event) => setCompanyName(event.target.value)}
        />
        <Input
          placeholder="Role"
          name="role"
          title="Role"
          onChange={(event) => setRole(event.target.value)}
        />
        <Input
          placeholder="Start Date"
          type="date"
          name="startDate"
          title="Start Date"
          onChange={(event: any) => setStartDate(event.target.value)}
        />
        <Input
          placeholder="End Date"
          type="date"
          name="endDate"
          title="End Date"
          onChange={(event: any) => setEndDate(event.target.value)}
        />
      </div>

      <div className="flex justify-between">
        <button className="text-secondBackground" onClick={() => setShowNewCompany(false)}>
          Cancel
        </button>
        {isLoading ? (
          <LoadingSpinner size="w-5 h-5" />
        ) : (
          <DefaultButton className="w-fit" onClick={handleSave}>
            Save
          </DefaultButton>
        )}
      </div>
    </div>
  );
};

export default AddNewCompany;
